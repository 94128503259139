.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 40px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .sidebar {
    height: auto;
    padding: 20px 28px;
    margin-top:40px;
    border: 1px solid #e6e6e6;
    a {
      display: grid;
      grid-template-columns: 2fr 9fr;
      align-items: center;
      background: none;
      color: #000;
      border: none;
      text-align: left;
      padding: 15px 0;
      img {
        width: 60%;
      }
      &.active {
        color: #fbb03b;
      }
    }
  }
  .bids {
    h2 {
      margin-bottom: 30px;
    }
    .bid-card {
      border: 1px solid #e6e6e6;
      padding: 18px;
      margin-bottom: 30px;
      img {
        width: 5.2vw;
        margin-right: 24px;
        object-fit: contain;
        @media screen and (max-width: 768px) {
          width: 25vw;
          height: 25vw;
          object-fit: contain;
        }
      }
      .name {
        font-size: 24px;
        font-weight: 300;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .country {
        color: #7f7f7f;
        font-size: 22px;
        font-weight: 200;
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
      .main-button {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      button {
        align-self: center;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}
