.shareBg{
  background:rgba(244, 242, 241, 0.85);
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  z-index:1000;
  display:none;
}
.shareBg.active{
  display:block;
}
.ra-overlay {
  display: none;
  width:400px;
  position:absolute;
  top:30px;
  left:50%;
  margin-left:-200px;
  height: 587px;
  background: white;
  z-index: 10001;
  border:1px solid #dfdcda;
}
.ra-overlay.ra-overlay-visible{
  display:block;
}
.ra-overlay .ra-overlay-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
}
.ra-overlay.ra-overlay-small .ra-overlay-header, .ra-overlay.ra-overlay-medium .ra-overlay-header {
  padding-left:30px;
  padding-top:30px;
}
.ra-heading.ra-heading-level-3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.ra-overlay.ra-overlay-small .ra-overlay-close, .ra-overlay.ra-overlay-medium .ra-overlay-close {
  position:absolute;
  right:30px;
  top:25px;
  font-size: 24px;
}
.ra-overlay .ra-overlay-close {
  margin-left: 15px;
  width: 30px;
  height: 40px;
  font-size: 27px;
  cursor: pointer;
}
.ra-overlay.ra-overlay-small .ra-overlay-body, .ra-overlay.ra-overlay-medium .ra-overlay-body {
  padding-top: 0;
  overflow-x: hidden;
}
.ra-overlay .ra-overlay-body {
  position: relative;
  padding-top: 20px;
}
.ra-share .ra-share-list .ra-share-list-item .ra-share-list-item-button .ra-share-list-item-button-inner > .anticon {
  font-size: 30px;
}
.ra-share .ra-share-list .ra-share-list-item .ra-share-list-item-button .ra-share-list-item-button-inner > span {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  line-height: 42px;
  font-weight:400;
}
.ra-share .ra-share-list .ra-share-list-item .ra-share-list-item-button {
  padding: 0 40px;
}
.ra-share .ra-share-list .ra-share-list-item .ra-share-list-item-button {
  width: 100%;
  background: none;
  border: none;
  padding: 0 20px;
}
.ra-share-list-item:hover{
  cursor:pointer;
  background: rgba(0,0,0,0.2);
}
.ra-share-list-item{
  cursor:pointer;
}
.ra-share-list-item:last-child span{
  line-height: 32px;
}
.ra-share .ra-share-list .ra-share-list-item .ra-share-list-item-button .ra-share-list-item-button-inner {
    display: -webkit-flex;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #ececec;
}
@media only screen and (max-width: 500px) {
  .ra-overlay{
    width:100%;
    top:0;
    left:0;
    margin-left:0px;
    height:100vh;
  }
}
/*# sourceMappingURL=main.css.map */
.product-details {
  .head-wrapper{
    display:block;
    .action-wrapper{
      position:absolute!important;
      .arrow-wrapper{
      display:flex!important;
      width:60px!important;
    }
    @media only screen and (max-width: 500px) {
      bottom:0px;
    }
    }
  }
  h3{
    text-align: left!important;
  }
  padding-top: 130px;
  margin-bottom: 100px;
  @media only screen and (max-width: 500px) {
  .main-pic svg{
    right:-10px!important;
  }
  }
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .yellow {
    color: #fbb03b;
    margin-left: 0.23vw;
    font-size: 1.3vw;
    font-weight: 300;
    @media only screen and (max-width: 768px) {
      font-size: 3.38vw;
    }
  }

  .red {
    color: #ff0000;
    font-size: 1.3vw;
    font-weight: 400;
    @media only screen and (max-width: 768px) {
      font-size: 3.38vw;
    }
  }
  .product-full {

    .gel{
      width:32px;
      height:32px;
      position:relative;
      top:2px;
      display:inline-block;
      background: url('../assets/images/gel.svg')no-repeat;
    }
    .shareMob{
      display:none;
      @media only screen and (max-width: 768px) {
        position: relative;
    top: 55px;
        display:block;
        float:right;
        font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  .shareIcon{
    width:24px;
    height:24px;
    display:block;
    float:left;
    margin-right:6px;
    background:url('../assets/images/share.svg')no-repeat;
  }
      }
    }
    .share{
      position:absolute;
      right:0;
      top:0;
      font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #000000;
@media only screen and (max-width: 768px) {
display:none;
}

      .shareIcon{
        width:24px;
        height:24px;
        display:block;
        float:left;
        margin-right:6px;
        background:url('../assets/images/share.svg')no-repeat;
      }
    }
    .originalArtwork{
      margin-top:48px;
      padding:20px;
      background: #F4F2F1;
      p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #111827;
        margin-bottom:16px;
      }
      p.resMarg{
        margin-bottom:0px;
      }
      .clock{
        display:block;
        width:20px;
        height:20px;
        margin-left:-2px;
        float:left;
        margin-right:11px;
        background:url('../assets/images/clock.svg')no-repeat;
      }
      .yes{
        display:block;
        width:16px;
        float:left;
        position:relative;
        top:3px;
        height:12px;
        margin-right:11px;
        background:url('../assets/images/yes.svg')no-repeat;
      }
    }
    .addCrt{
      
    margin-top:40px;
    display:block;
      width:100%;
      button.crt{
        height: 52px;
        background: #000000;
        border-radius: 26px;
        width:calc(100% - 76px);

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        color: #FFFFFF;
        float:left;
        @media only screen and (max-width: 768px) {
          width:198px;
        }
      }
    button.favoritesIcons{
      cursor:pointer;
      width:52px;
      height:52px;
      float:right;
      @media only screen and (max-width: 768px) {
float:left;
margin-left:28px;
      }
      border: 1px solid #111827;
      border-radius: 5px;
      padding:0px;
      background:white;
      position:relative;
      &::before{
        content:' ';
        width:24px;
        height:24px;
        background:url('../assets/images/heartFull.png')no-repeat;
        display:block;
        top:14px;
        left:13px;
        position:absolute;
      }
    }
    }
    .sizes{
      display:block;
      margin-bottom:30px;
      .desc{
        margin-left:9px;
        margin-top:0px;
        width:auto!important;
      }
    }
    .currency{
      font-family: 'Inter';
      font-style: normal;
      font-size: 32px;
      line-height: 39px;
      color: #FBB03B;
      font-weight:100;
      margin-right:4px;
    }
    .paintings{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      float:left;
      .dash{
        margin-left:5px;
      }
    }
    .artist-name {
      margin-bottom:27px;      
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #111827;


      @media only screen and (max-width: 768px) {
        font-size: 5vw;
      }
    }
    .flex {
      .flex {
        img {
          width: 1.5vw;
        }
      }
    }
    margin: 2.44vw 0;
    .main-pic {
      width: 592px;
      @media only screen and (max-width: 1233px) {
          width:382px;
      }
    }
    .list {
      img {
        margin: 0 1.14vw 1.77vw 0;
        -o-object-fit: contain;
        object-fit: contain;
        width: 6.14vw;
      }
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 20px;
      color: #000000;
      margin-bottom:83px;
    }
    .id {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-bottom:28px;
      color: #9CA3AF;
    }
    .current {
      font-size: 16px;
      margin-bottom: 0.83vw;
    }
    .price-cont {
      margin-bottom: 0.67vw;
      width: 12.08vw;
      font-size: 1.66vw;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #ff0000;
      .bids {
        font-size: 1.3vw;
        color: #cecece;
      }
    }
    .main-pic {
      margin-right: 34px;
    }
    .bid {
      width: 25.68vw;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 3.1vw;
      .price {
        font-size: 1.66vw;
      }
      button {
        background: #fbb03b;
        color: #fff;
        border: none;
      }
    }
    .buyitnow {
      width: 190px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .price {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #FBB03B;
      }
      button {
        border: 2px solid #fc0d1b;
        background: transparent;
        color: #fc0d1b;
      }
    }
    input {
      border: 1px solid #d8d8d8;
      border-radius: 2.6vw;
      padding: 1.3vw 2.34vw;
      font-size: 0.83vw;
    }
    button {
      border: 1px solid #d8d8d8;
      border-radius: 2.6vw;
      padding: 1vw 2vw;
      font-size: 0.83vw;
    }
    h2 {
      margin-top:32px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #111827;
      margin-bottom:12px;
    }
    .desc {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #374151;
      float:left;
    }
  }

  .product-page {
    //  padding: 3.2vw 0;
    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 24.5vw;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 0.78vw;
      color: #b2b2b2;
      img {
        width: 0.78vw;
        -o-object-fit: contain;
        object-fit: contain;
      }
      .black {
        color: #000;
      }
    }
  }
  .clck{
      margin-top:0.1em;
  }
}
footer{
  background:transparent!important;
}
@media only screen and (max-width: 768px) {
  .product-details {
    //  .container {
    //    width: 78.9vw;
    //  }
    .product-full {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      h1 {
        margin-top: 31px;
        margin-bottom:32px;
      }
      .id {
        margin-bottom: 16px;
      }
      .current {
        font-size: 3.86vw;
      }
      .price-cont {
        font-size: 5.79vw;
        width: 34.78vw;
        .bids {
          font-size: 3.86vw;
        }
      }
      .list {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 2.41vw;
        a {
          width: 33%;
          margin: 0;
          img {
            width: 100%;
          }
        }
      }
      .main-pic {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        margin: 0;
      }
      .text {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        .clock {
          order: -1;
        }
      }
      .bid {
        width: 100%;
        margin-top: 5.07vw;
        margin-bottom: 10.6vw;
        .price {
          font-size: 5.79vw;
        }
      }
      .buyitnow {
        width: 100%;
        .price {
          font-size: 5.79vw;
        }
      }
      input {
        width: 25vw;
        font-size: 4vw;
        padding: 4.3vw 6vw;
        border-radius: 12vw;
      }
      button {
        font-size: 4vw;
        padding: 4.3vw 6vw;
        border-radius: 12vw;
      }
      h2 {
        font-size: 3.86vw;
        margin-top: 1vw;
      }
      .desc {
        font-size: 3.38vw;
        width: 100%;
      }
    }
    .product-page {
      .title {
        display: none;
      }
    }
  }
}
