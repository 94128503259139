.subscribe{
  position:relative;
  margin-top:28px;
  input{
    position:absolute;
    width:calc(100% - 93px);
    border:0px;
    border-bottom:1px solid black;
    padding:0px;
    font-family:'MonumentGrotesk-Regular';
  }
  button{
    border:0px;
    border-top:1px solid black;
    border-bottom:1px solid black;
    padding:1px 16px 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    top:-5px;
    color: #000000;
    padding-top:3px;
    font-family:'MonumentGrotesk-Regular';
    background:white;
    position:absolute;
    right:0;
    height: 24px;
    width: 85px;
    transition:all 0.5s;
  }
  button:hover{
    background:black;
    color:#fff;
  }
}