.logo-container{
  .logo{
      width:48px;
      height:16px;      
      margin-top:0px;
      margin-left:10px;  
  }
}
nav{
  position:fixed!important;
  background:white;
  z-index:2;
  width:100%;
  height:32px;
  a.nav-link{
    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #000000;
    padding:8px 31px 5px !important;
    font-feature-settings: 'salt' on;
  }

  .searchIcon{
    margin-right:37px;
    position: relative;
    top:-3px;
    float:left;
    cursor:pointer;
    width:32px;
    height:32px;
    background:url('../assets/icons/search-dark.svg') no-repeat;
  }
  .frameIcon{
    position: relative;
    top:9px;
    margin-right:8px;
    transition:all 0.5s;
  }
  .frameIcon:hover{
    background:url('../assets/icons/frameHover.svg') no-repeat;
  }
  .cart-btn{
    transition:all 0.5s;
    position: relative;
    top:9px;
    margin-right:8px;
    display:inline-block;
    width:14px;
    height:14px;
    background:url('../assets/icons/cart-dark.svg');
  }
  .cart-btn:hover{
    background:url('../assets/icons/cart-dark-hover.svg');
  }
  .user-btn{
    transition:all 0.5s;
    position: relative;
    top:9px;
    display:inline-block;
    width:14px;
    height:14px;
    background:url('../assets/icons/user-dark.svg');
  }
  .user-btn:hover, .user-btn.active{
    background:url('../assets/icons/userHover.svg');
  }
}
section{
  padding-top:32px;
}

.actionButtons{
  background:transparent;
  border:0px;

  color: #000;
text-align: center;
font-feature-settings: 'salt' on;
font-family: 'MonumentGrotesk-Regular';
font-size: 12px;
font-style: normal;
font-weight: 500;
height:32px;
width:55px;
line-height: 16px; /* 133.333% */
}
.signUpButton{
  background:#000;
  color:#fff;
  width:54px;
}


.oLogIn{
  display:none;
}

@media only screen and (max-width: 991px) {
  .oLogIn{
      display:inline-block;
  }
  .navOffsetHide{
    display:none;
  }
}
.actionButtons{
  transition:0.5s all;
}
.actionButtons.LogOut{
  color: #B3B3B3;
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: 'MonumentGrotesk-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.logInBtn:hover,.loginActive{
  color: #fff;
  background:black;
}

.singupInactive{
  color:black;
  background: transparent;
}

.chatIcon{
  display:none;
  position:fixed;
  cursor:pointer;
  bottom:11px;
  right:28px;
  z-index:1;
  width:48px;
  height:48px;
}
.loginHeader{
  position:fixed;
  background:white;
  max-height:0px;
  transition:0.5s all;
  top:32px;
  opacity:0;
  width:100%;
  padding-left:10px;
  padding-right:23px;
  z-index: 10;
  overflow:hidden;
  box-shadow:0px 40px 50px rgba(0, 0, 0, 0.25);
  ul{
    width:100%;
    padding:0px;
    li{
      font-family: 'Loos Normal';
      font-style: normal;
      font-weight: 400;
      font-size: 72px;
      line-height: 72px;
      font-feature-settings: 'salt' on;
      color: #000000;
      opacity:0.2;
      transition:all 0.5s;
      height:72px;
      cursor:pointer;
    }
    li:last-child{
      height:auto;
    }
    li.active{
      opacity:1;
    }
    li:last-child{
      margin-top:4px;
      font-family: 'MonumentGrotesk-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      font-feature-settings: 'salt' on;
      opacity:1;
      color: #000000;
      a{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-decoration: none;
      }
      a::before{
        content:' ';
        width:12px;
        height:12px;
        background:#000;
        display:inline-block;
        border-radius:12px;
        margin-left:11px;
        margin-right:2px;
        position:relative;
        top:1px;
      }
    }
  }
}
.loginHeader.active{
  max-height:176px;
  opacity:1;
}
.inputs{
  display:flex;
  position:relative;
}
.regPage{
 
  padding-right:0px;
  input[type='text'],input[type='password']{
    border:0px;
    background:transparent;
    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #000;
    border-bottom:1px dashed #B3B3B3;
    padding-bottom:3px;
    margin-top:15px;
    margin-right:11px;
    width:40%;
  }
  input[name='search']{
    width:100%;
  }
  input::placeholder{
    color: #B3B3B3;
  }
  .loginInputs{
    justify-content: end;
  }
  .regInputs{

    p{
      position:absolute;
      bottom:-48px;
      margin-top:20px;
      font-family: 'MonumentGrotesk-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      font-feature-settings: 'salt' on;
      color: #000000;
      opacity: 0.2;
    }
  
  }

}
.secondL{
  margin-top:20px;
  min-height:19px;
}
.regPage.regpagMarging{
  display:flex;
  flex-grow: 1;
  padding-top:21px;
  gap:3px;
  label{
    display: flex;
    width: 33%;
  }
  span{
    display: block;
    width: 100%;
    font-size:12px;
    text-align: center;
    cursor:pointer;
    background:#E6E6E6;
    line-height: 20px;
    color:rgba(0, 0, 0, 0.2);
    height:20px;
  }
  .typeActive{
    background: black;
    color:white;
  }
}
.loginHeader button.register, .loginHeader button.search{
  background:transparent;
  margin-top:89px;
  border:0px;
  width:100%;
  height:40px;
  border-top:1px solid black;
  border-bottom:1px solid black;
  float:right;
  font-family: 'MonumentGrotesk-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-feature-settings: 'salt' on;
  color: #000000;
  transition:0.5s all;
}
.loginHeader button.register:hover, .loginHeader button.search:hover{
  color:white;
  background:black;
}
.regPage.active{
  display:block;
}
.headerBg{
  position:fixed;
  opacity:0;
  top:100px;
  background:rgba(255,255,255,0.7);
  width:100%;
  height:100vh;
  z-index: -1;
  transition: 0.5s all;
}
.headerBg.active{    
  z-index: 5;
  opacity:1;
}
.noVisible,.mobile_menu{
  display:none;
}
.mobile_menu{
  font-family: 'MonumentGrotesk-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right:8px;
  align-items: center;
  text-align: right;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-feature-settings: 'salt' on;
  color: #000000;
  background:transparent;
  border:0px;
}

.collapse.show{
  background:white;
  position:absolute;
  top:31px;
  width:100%;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  nav .user-btn, nav .cart-btn, nav .frameIcon{
    top:4px;
  }
  .groupItems{
    position: relative;
    top:-4px;
  }
  .mobile_menu{
    display:block;
  }
  nav{
    .searchIcon{
      display:none;
    }
  }
}
@media only screen and (max-width: 768px) {
  .loginHeader{
    height:auto;
    padding-bottom:15px;
    position:absolute; 
    padding-right:10px;    
  }
  .loginHeader.active{
    max-height:700px;
  }
  .navbar-nav li:first-child a{
    padding-left:31px!important;
  }
  .navbar-nav a.nav-link{
    text-align: right;
    padding-right:8px!important;
}
  .regPage{
    padding-top:0px;
    .loginInputs{
      input[type='text'],input[type='password']{
      width:50%;
    }
    }
    
  }
  button.register{
    margin-top:50px;
  }
  
}