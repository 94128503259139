.special_projects{
  padding-top:64px;
  padding-left:10px;
  a{
    text-decoration: none;
  }
  h3{
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
  }
  .action-wrapper{
    display:none;
  }
}
.slickFull{

  img{
    height:350px;
    object-fit: cover;
    @media only screen and (max-width: 1220px) {
      height:300px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .slickFull{
    img{
      width:100%;
    }
  }
}
//.slick-list{padding:0 20% 0 0 !important;}