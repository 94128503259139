.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  //Custom radio

  .wrapper {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;

    background: #fff;
    height: 75px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 40px;
    padding: 20px 0;

    .option {
      background: #fff;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 5px;
      border-radius: 40px;
      cursor: pointer;
      padding: 0 5px;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
      span {
        font-size: 15px;
        color: #808080;
      }
    }
  }
  input[type="file"] {
    padding: 10px;
  }
  input[type="radio"] {
    display: none;
  }
  #option-1 {
    &:checked {
      &:checked {
        ~ {
          .option-1 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
  #option-2 {
    &:checked {
      &:checked {
        ~ {
          .option-2 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  #option-3 {
    &:checked {
      &:checked {
        ~ {
          .option-3 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  #option-4 {
    &:checked {
      &:checked {
        ~ {
          .option-4 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
  #option-5 {
    &:checked {
      &:checked {
        ~ {
          .option-5 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
  #option-6 {
    &:checked {
      &:checked {
        ~ {
          .option-6 {
            border-color: #fbb03b;
            background: #fbb03b;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .modal-container {
    position: relative;
    height: 80%;
    width: 90%;
    max-width: 470px;
    background-color: white;
    padding: 25px;
    padding-top: 45px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .close-btn {
      position: absolute;
      top: 15px;
      right: 25px;
      font-size: 25px;
      cursor: pointer;
    }
    h3 {
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
      color: #7d7d7d;
      text-align: center;
      line-height: 25px;
      margin-bottom: 17px;
    }
    input[type="email"],
    input[type="text"],
    input[type="password"],
    input[type="file"] {
      width: 100%;
      height: 55px;
      border: 1px solid #d8d8d8;
      border-radius: 50px;
      font-size: 18px;
      color: black;
      padding-left: 30px;
      padding-right: 10px;
      margin-bottom: 10px;
    }
    .action-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
      margin-bottom: 24px;
      .keep-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        span {
          margin-left: 5px;
          margin-top: 1px;
        }
      }
      .forget {
        color: #7d7d7d;
      }
    }
    .keep-wrapper-signup {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-left: 5px;
      padding-right: 5px;
      span {
        margin-left: 5px;
        margin-top: -5px;
        font-size: 12px;
      }
    }
    .modal-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      .btn {
        //   width: 140px;
        width: 48%;
        height: 47px;
        font-size: 10px;
      }
      .btn-gmail {
        color: black;
      }
    }
    .btn-signup {
      margin-top: 10px;
      width: 100%;
      height: 47px;
      padding: 10px 0;
      font-size: 12px;
      font-weight: 300;
    }
    .href-signup {
      font-weight: 300;
      color: black;
      strong {
        font-weight: 500;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .auth-modal {
    .modal-container {
      height: 98%;
      max-height: 800px;
      overflow-y: scroll;
      width: 580px;
      min-width: 580px;
      max-width: 580px;
      padding: 75px;
      padding-top: 70px;
      padding-bottom: 55px;
      h3 {
        margin-bottom: 10px;
        font-size: 33px;
      }
      p {
        font-size: 14px;
        color: #7d7d7d;
        text-align: center;
        line-height: 25px;
        margin-bottom: 20px;
      }
      input[type="file"] {
        padding: 15px;
      }
      input[type="text"],
      input[type="password"],
      input[type="file"] {
        font-size: 18px;
        height: 55px;
        padding-left: 30px;
      }

      .action-wrapper {
        font-size: 16px;
        padding-left: 5px;
        padding-right: 5px;
      }
      .modal-btn-container {
        .btn {
          width: 48%;
          height: 60px;
          font-size: 16px;
        }
      }
      .btn-signup {
        height: 55px;
        font-size: 16px;
      }
      .input-container {
        .terms-span {
          font-size: 15px;
        }
      }
    }
    .href-signup {
      font-size: 16px;
    }
  }
}
