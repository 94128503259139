* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 200;

  -webkit-appearance: none;
}



* {
  &:active,
  :focus {
    outline: none;
  }
}

:root {
  --h: 5rem;

  --g-red: #d04b36;
  --g-orange: #e36511;
  --g-yellow: #ffba00;
  --g-green: #00b180;
  --g-blue: #147aab;
  --g-indigo: #675997;

  --t-blue: #4fa5c2;
  --t-pink: #f587ac;
  --t-white: #f9fbfc;

  --b-pink: #c1357e;
  --b-purple: #675997;
  --b-blue: #0655a9;

  --p-magenta: #fa5e5b;
  --p-yellow: #ffba00;
  --p-blue: #4fa5c2;

  --a-black: #000000;
  --a-grey: #a3a3a3;
  --a-white: #ffffff;
  --a-purple: #800080;

  --nb-black: #000000;
  --nb-yellow: #fff433;
  --nb-white: #ffffff;
  --nb-purple: #9b59d0;
}

.error {
  color: red;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
}

.center {
  justify-content: center;
  align-items: center;
}
.product .title{
  max-width:90%;
  height:35px;
  overflow:hidden;
}
.right {
  justify-content: flex-end;
  align-items: flex-end;
}
.smaller {
  input,
  select {
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
      padding: 15px 12px !important;
    }
  }
}
.viewAll{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

text-align: center;
color: #000000;
width: 96px;
height: 38px;
border: 1px solid #FBB03B!important;
}
.nextButton{
  width:88px;
}
.prevButton{
  width:113px;
}
@media screen and (max-width: 510px) {
  .nextButton{
    width:auto;
  }
  .prevButton{
    width:auto;
  }
  .nextButton .next{
    margin-left:0px;
  }
}
.favoritesIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  top: 0;
  @media screen and (max-width: 768px) {
    position: relative;
  }
}

.pagination {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.6vw;

  li {
    margin: 0 0.15vw;
    list-style-type: none;
    button {
      padding: 9px 16px;
      .pageText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        @media screen and (max-width: 510px) {
          display:none;
        }
      }
      outline: none;
      border: none;
      color: #000;
      transition: 300ms ease;
      background: transparent;
      cursor:pointer;
      font-size:14px;
      font-weight:400;
      &.active {
        color: #fff;
        background: #FBB03B;
border-radius: 6px;
      }
   
   
    }
  }
  @media screen and (max-width: 427px) {
    li:nth-child(6){
      display:none;
    }
    li{
      button{
        padding:7px 14px;
      }
    }
  }
  .prevButton{

    .previous{
      display:block;
      margin-top:4px;
      width:8px;
      height:12px;  
      transform: rotate(180deg);
      background:url('../assets/images/next.svg')no-repeat;
      float:left;
      margin-right:16px;
    }
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;    
    color: #000000;  
  }
}

.nextButton{

  .next{
    display:block;
    margin-top:4px;
    width:8px;
    height:12px;  
    background:url('../assets/images/next.svg')no-repeat;
    float:right;
    margin-left:16px;
  }
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;    
  color: #000000;  
}


.masonry-grid {
  display: flex;
  flex-wrap: wrap;

  .fullHeart{
    position:absolute;
    transition:all 500ms ease;
    width:48px;
    height:48px;
    bottom:8px;
    right:16px;
    background: #FFFFFF;
    border-radius:48px;
    opacity:0;
    @media screen and (max-width: 768px) {
     opacity:1;
    }
    &::before{
      content:' ';
      width:24px;
      height:24px;
      background:url('../assets/images/fullHeart.svg')no-repeat;
      display:block;
      top:14px;
      left:12px;
      position:absolute;
    }
  }

  div:hover .fullHeart{
    opacity:1;
  }
  div {
    height: 300px;
    line-height: 300px;
    position:relative;

    text-align: center;
    font-family: system-ui;
    font-weight: 900;
    font-size: 2rem;
    flex: 1 0 auto;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      height: 150px;
    }

    img {
      transition: 300ms all ease;
      &:hover {
        transform: scale(1.1);
      }
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
   


  }
 
  @for $i from 1 through 36 {
    div:nth-child(#{$i}) {
      $h: 25%;
      width: $h;
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;

  a {
    border: 1px solid #d8d8d8;
    padding: 15px 30px;
    font-size: 15px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #d8d8d8;

  z-index: 1;
  flex-direction: column;
  button {
    background: transparent;
    padding: 15px 30px;
    border: none;
    &:hover {
      background: #fbb03b;
      cursor: pointer;
      color: #fff;
    }
  }
}

.dropdown:hover .dropdown-content {
  display: flex;
}

html,
body {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  font-size: 12px;
}

body {
  background-color: white;
}

#root {
  // position: relative;
  height: 100%;
}

.flex {
  display: flex;
}

.loading {
  display:none;

  
 
    width:50px;
    height:50px;
    --c:radial-gradient(farthest-side,#FBB03B 92%,#0000);
    background: 
      var(--c) 50% 0,
      var(--c) 50% 100%,
      var(--c) 100% 50%,
      var(--c) 0    50%;
    background-size:12px 12px;
    background-repeat:no-repeat;
    animation:s7 1s infinite;
    position:fixed;
    z-index: 1000;
    left:calc(50% - 25px);
    top:calc(50% - 25px);

}
.loadingbg{
  display:none;
  position:fixed;
  height:100vh;
  width:100%;
  left:0;
  content:' ';
  top:0;
  z-index: 999;
  background:white;
 }
@keyframes s7 {to{transform: rotate(.5turn)}}
.space-between {
  justify-content: space-between;
}

.column {
  flex-direction: column;
}

.main-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 22px;
  border-radius: 6px;
  cursor: pointer;
  transition: 300ms;
  &.many {
    margin: 0.5em 0;
  }
  @media screen and (max-width: 768px) {
    padding: 5px 11px;
  }
  &:hover {
    opacity: 0.5;
  }
}

ul.breadcrumb {
  padding: 10px 16px 10px 0;
  list-style: none;
  margin-bottom: 3em;

  li {
    display: inline;
    font-size: 14px;

    a {
      color: #b2b2b2;
      text-decoration: none;
      &:hover {
        color: #01447e;
      }
    }
  }
  li + li {
    &:before {
      padding: 8px;
      color: #b2b2b2;
      content: "/\00a0";
    }
  }
}

a {
  color: #000;
}

.soldDetailed {
  img {
    width: 50%;
    object-fit: contain;
  }
}

.new-shop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 1224px){
    grid-template-columns: 1fr 1fr 1fr;
    
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.slick-slide{
  padding:18px;
  padding-right:10px;
  padding-left:0px;
}
.hero-wrapper .slick-slide{
  padding:0px;
}
.art_org{
  padding-left:6px;
  padding-right:4px;
  width:100%;
}
.product {
  position:relative;
  height:360px;
    width:280px;
  padding-top:7px;
  margin-bottom: 2em;
  

  .title_tag{
    padding-left:12px;
    padding-right:12px;
    height:75px;
    border-right: 1px solid rgba(209, 213, 219, 0.6);
    border-left: 1px solid rgba(209, 213, 219, 0.6);
    .title{
      padding-top:12px;
    }
    .depth{
      font-size:12px;
      padding-top:4px;
      font-weight:400;
    }
  }
  .price_tag{
    font-weight:bold;
    padding-left:12px;
    padding-top:14px;
    padding-bottom:0px;
    padding-right:12px;
    border: 1px solid rgba(209, 213, 219, 0.6);
    border-top:0px;
    .gr{
      display:block;
      width:100%;
    }
    .price{
      position:absolute;
      right:13px;
      bottom:0px;
    }
  }

  .sold {
    position: absolute;
    width: 90%;
    height: 80%;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-height:461px;
      width: 70%;
      object-fit: contain;
    }
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  .img {
    height:270px;
    width: 100%;
    position:relative;
    text-align: center;
    svg{
      position:absolute;
      top:-10px!important;
      right:-10px!important;
      display:none;
    }
    .group{

      overflow:hidden;
      width:100%;
    position: absolute;
    top:0;
    border: 1px solid rgba(209, 213, 219, 0.6);
    border-bottom:0px;
      height:270px;
    
    img{
      max-width:100%;
      height:270px;
      
    }
  }

    @media screen and (max-width: 768px) {
    
      width: 100%;
    }
    img {
      transition: all 150ms;
      width: 100%;

    }
  }
  .title i,.titles{
    font-weight: 400;
font-size: 14px;
font-style: normal;
color: #111827;
  }
  .price,
  .time {
    font-size: 1.3rem;
    &.gray {
      margin-left: 0.5em;
      color: #cecece;
      bottom:25px!important;
    }
  }
  .price{
    font-weight:600;
    color:#FBB03B;
  }
  .heart{
    position:absolute;
    right:12px;
    bottom:48px;
    cursor:pointer;
    
    width:20px;
    height:20px;
    background:url('../assets/images/heartFull.svg')no-repeat;
  }
}
.removeFavorite{
  position:relative;
  top:-15px;
  @media screen and (max-width: 700px){
    top:-8px;
  }
}
.all_works_by{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 39px;
color: #000000;
display:block;
margin-top:80px;
margin-bottom:32px;
}
.inbio{
  position:relative;
  h3{
    text-transform: uppercase;
  }
  .selectedFor .head-wrapper{
    justify-content: left;
    margin-top:-10px;
  }
  .action-wrapper{
    position:absolute!important;
  }
  @media screen and (max-width: 800px){
  .selectedFor .head-wrapper{
    position: absolute;
    width: 100%;
  .arrow-wrapper{
    margin-right:60px!important;
  }
  @media screen and (max-width: 500px){
  margin-top: -50px;
  text-align: left;
  font-size:18px;
  }
  }
}
  .action-wrapper{
    a{
      display:none;
    }
  }
}
.shop-grid{

 .product{
  height:auto;
  .price_tag .price{
    bottom:5px;
  }
 }
 
 
 .product .img .group img,.product .img .group,.product .img{
  height:270px;
 }
}

.no_works{
  text-align: center;
  color:#000;
  font-size:36px;
}

.curators_choice{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #FFFFFF;
  width: 304px;
height: 57px;
line-height:57px;
background: #000000;
margin:auto;
margin-bottom:33px;
display:block;
}

.MuiSlider-marked{
  top:-3px;
}
@media screen and (max-width: 1040px){
  .MuiSlider-marked{
    margin-left:14px;
  }
}
.shop_filter{
  padding-bottom:40px;
  padding-top:120px;
  background:#F9FAFB;

  .fullA{
    overflow:hidden;
font-style: normal;
font-weight: 500;
font-size: 28px;
@media screen and (max-width: 1224px){
  margin-top:30px;
  font-size:22px;
}
@media screen and (max-width: 1040px){
  max-width:418px;
  margin:auto;
}


text-align: center;

width:100%;

margin-top:50px;
color: #111827;
margin-top:12px;
h2{
  font-weight: 400;
  margin-top:12px;
font-size: 18px;
@media screen and (max-width: 1224px){
  font-size:16px;
  margin-top:2px;
}
line-height: 20px;
text-align: left;

margin-bottom:0px;

color: #000000;
}
.MuiSlider-thumb{
width:16px!important;
height:16px!important;
margin-top:-8px!important;
}
.p-hidden-accessible input{
  opacity:0;
}
.makeStyles-root-1,.jss1,.forrangeSlide div{
  width:271px;
  @media screen and (max-width: 1224px){
    width:184px;
  }
  @media screen and (max-width: 450px){
    width:100%
  }
}
  }
  .box{
    float:left;
    margin-right:37px;
    @media screen and (max-width: 1224px){
      margin-right:30px;
    }

    @media screen and (max-width: 1040px){
      margin-right:21px;
      margin-bottom:20px;
    }
    @media screen and (max-width: 450px){
      width:calc(50% - 24px);
    }  
  }
  @media screen and (max-width: 1040px){
    .boxS{
      margin-right:0px!important;
    }
  }
  @media screen and (max-width: 450px){
    .boxS{
      width:50%;
    }
    .box.forrangeSlide{
      width:calc(50% - 35px);
    }
    .boxS.forrangeSlide{
      width:calc(50% - 15px);   
    }
    .forrangeSlide{
      .MuiSlider-markLabel[data-index="1"]{
        left:auto!important;
        right:-18px!important;
      }
    }
  }
 
  
  .clearButton{
    width: 128px!important;
height: 52px!important;
border: 1px solid #000000!important;
border-radius: 8px!important;
background:transparent!important;

font-weight: 400;
font-size: 16px;
line-height: 20px;
text-align: center;

margin-top:20px;
float:right;
@media screen and (max-width: 1200px){
float:left;
}
color: #FBB03B;
@media screen and (max-width: 1040px){
  margin-top:0px;
}
  }
  
  select {

    /* styling */
    background-color: transparent;
    border:0px;
    border-bottom: 1px solid #D1D5DB;
    border-radius: 0px;
    display: inline-block;
    font: inherit;
    width:176px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:20px;
    padding-top:18px;
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }
  
  
  /* arrows */
  .p-multiselect{
    width:172px;
    @media screen and (max-width: 1040px){
      width:192px;
    }
    @media screen and (max-width: 450px){
      width:100%;
    }
    background: transparent;
    border:0px;
    border-radius:0px;
    border-bottom: 1px solid #D1D5DB;
  }
  .p-multiselect:hover{
    border-bottom: 1px solid #D1D5DB!important;
  }
  .p-focus{
    box-shadow:0px 0px 0px !important;
    
    border-bottom: 1px solid #D1D5DB!important;
  }

  select.customSelect {
    background:url('../assets/images/down_arrow.png')no-repeat;
    background-position: right center;
  }
  .box{
    position:relative;
    label{
      width:172px;
      text-align: left;
      display:block;
      left:0;
      margin-top:20px;
      z-index:0;
      position:absolute;
      font-style: normal;
    font-weight: 400;
    font-size: 18px;
    @media screen and (max-width: 1224px){
      font-size:16px;
      margin-top:10px;
    }
    line-height: 20px;


    color: #000000;
    }
  }
 
  
  
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  background:#FBB03B!important;
}
.p-multiselect-header .p-checkbox-box,.p-multiselect-header,.p-checkbox{
  display:none!important;
}
.p-multiselect-label{
  font-weight:400!important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item span{
  font-style: normal;
font-weight: 400!important;
font-size: 16px;
line-height: 20px;
color: #000000!important;
}
.newIn{
  a{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  padding-right:10px;
  line-height: 29px;
  text-decoration-line: underline;
  color: #FBB03B;
  border:0px!important;
  }
}
.curator_page{
  .action-wrapper{
    position:absolute!important;
  }.selectedFor .head-wrapper{
    height:30px;
  }
  .selectedFor .head-wrapper .action-wrapper .arrow-wrapper {
    display: flex!important;
}
}
.curators{
  margin-top:50px!important;
  @media screen and (max-width: 1224px){
  .info-wrapper{
    width:100%;
  }

  .info-col{
    padding:5px;
  }
}
@media screen and (max-width: 500px){
  .info-wrapper{
    padding-left:0px;
    padding-right:0px;
  }
}
  .head-wrapper{
    border-bottom: 1px solid #D1D5DB;
    display:block!important;
  }
  h2{
    display:block;
    float:right;
    width: 304px;
    height: 57px;
    text-align: center;
    display:block;
    background: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 57px;
    color: #FFFFFF;
  }
  img{
    width:215px;
    height:215px;
    border-radius:215px;
    margin-bottom:16px;
    @media screen and (max-width: 1224px){
      width:153px;
      height:153px;
      border-radius:153px;
    } 
  }
  h1{
    font-style: normal;
    height:55px;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 500px){
      font-size:13px;
    }
    line-height: 33px;
    color: #000000;
  }
  .info-wrapper{
    padding-top:47px;
  }
  @media screen and (max-width: 900px){
    .info-row{
      display:grid;
      grid-template-columns:1fr 1fr;
    }
  }
  .info-col{
    margin:auto;
    flex: 0 0 25%;
    max-width: 25%;
    @media screen and (max-width: 900px){
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom:32px;
    }

  }
}

/* The container */
/* Hide the browser's default checkbox */
/* Create a custom checkbox */
/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
/* Show the checkmark when checked */
/* Style the checkmark/indicator */
.container-checkbox {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.7;

  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #000;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: #ccc;
        }
      }
    }
  }
  .checkmark {
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.lgbtq {
  margin: auto;
  text-align: center;
  width: 700px !important;
  font-size: 1.5em;
  padding-top: 1em !important;
  word-wrap: break-word;
  @media screen and (max-width: 768px) {
    width: 350px !important;
    font-size: 1em;
  }
}

.lgbtq-img {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 700px !important;
  font-size: 1.5em;
  padding-top: 1em !important;
  word-wrap: break-word;
  @media screen and (max-width: 768px) {
    width: 90% !important;
    font-size: 1em;
    img {
      width: 100%;
    }
  }
}

.gay {
  background-image: linear-gradient(
    var(--g-red) 0%,
    var(--g-red) 16.6666%,
    var(--g-orange) 16.6666%,
    var(--g-orange) 33.333%,
    var(--g-yellow) 33.333%,
    var(--g-yellow) 50%,
    var(--g-green) 50%,
    var(--g-green) 66.6666%,
    var(--g-blue) 66.6666%,
    var(--g-blue) 83.3333%,
    var(--g-indigo) 83.3333%,
    var(--g-indigo) 100%
  ) !important;
}

.comment-input {
  width: 100%;
  margin-top: 1em;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  padding: 1em;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }

  &.button {
    cursor: pointer;
    width: auto;
    position: absolute;
    right: 0;
    top: 1.05em;
    background-color: #fbb03b;
    border: none;
    border-radius: 40px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      position: relative;
      width: 100%;
    }
  }
}

.commentbox {
  position: relative;
}

.comment {
  border: 1px solid #d8d8d8;
  padding: 1em 2em;
  margin: 1em 0;
  border-radius: 10px;

  h4 {
    font-size: 1.2em;
    color: #afaeae;
  }
  p {
    font-size: 2em;
  }
}

.auc-red {
  color: #ff0000;
  font-weight: 400;
  }


  .progress {
    display: none;
    width: 100%;
    height: 3px;
    overflow: hidden;
    
    background-color: #D1D5DB;
    background-image: linear-gradient(to right, black, black);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    
    transition: background-size .4s ease-in-out;
    
  }
  

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  @media screen and (max-width: 420px) { 
    .progress{
      display:block;
    }
    .title_tag{
      height:70px;
    }
    .slick-list{
      padding:0px!important;
    }
  
    .product .title_tag .title{
      max-height:35px;
      overflow:hidden;
    }
   .s_co{
    padding:20px!important;
    .searchIcon{
      top:55px!important;
    }
   }
  }


  @media screen and (max-width: 1059px) {
    .product{
      width:255px;
    }
  }
  @media screen and (max-width: 768px) {
    .product{
      width:305px;
    }
  }
  .artM{
    margin-top:70px;
  }
  @media screen and (max-width: 500px) {
    .artM{
      margin-top:60px;
    }
    .slick-slide{
      padding-right:19px;
    }
    .product{
      width:306px!important;
    }
    .product .img, .product .img .group,.product .img .group img{
      height:308px;
    }
    .new-shop,.auctions{
      .product{
        margin:auto;
        margin-bottom:20px;
      }
    }
  }
//imports

@import "./config.scss";
@import "./navbar.scss";
@import "./modal.scss";
//main
@import "./main.scss";
@import "./hero.scss";
@import "./banners.scss";

//trustpanel
@import "./trustPanel.scss";


//shared slider

@import "./sharedSlider.scss";
@import "./card.scss";

//shared subscribe

@import "./subscribe.scss";

//shop

@import "./shop.scss";
@import "./filter.scss";
@import "./footer.scss";
@import "./404.scss";
@import "./produt-det.scss";

//textpages

@import "./text.scss";

//contact

@import "./contact.scss";

//auctions
@import "./auctions.scss";

//auctions
@import "./galleries.scss";

//dashboard
@import "./dashboard.scss";

//artists
@import "./artists.scss";

//artworks
@import "./artworks.scss";

//cart
@import "./cart.scss";

//cards
@import "./Cards.scss";