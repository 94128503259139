.trustPanel {
    .trustItem h2 {
        font-weight: 400;
    }
    .trustItem svg {
        vertical-align: middle;
        margin-right:15px;
    }

    background: rgb(247, 244, 244);
    padding-top:40px;
    padding-bottom: 40px;
    width: 100%;
    text-align: center;
}