  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    .textContainer {
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 28px;
        color: #fbb03b;
        margin: 0 0 24px 0;
        font-weight: 500;
      }
      
    .description {
      font-size: 16px;
      font-weight: 500;
      
    }
  }
    
    .img {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
    .button {
      font-size: 18px;
      align-items: center;
      color: #fff;
      text-align: center;
      padding: 16px 20px;
      background: #000;
      border-radius: 40px;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.86);
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    
    input, textarea {
      font-size: 18px;
      margin-bottom: 16px;
    }
    textarea {
      height: 128px;
    }
    .button {
      color: #fff;
      text-align: center;
      padding: 16px 20px;
      background: #000;
      border-radius: 40px;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      &:hover {
        background: rgba(0, 0, 0, 0.86);
      }
    }
  }

  @media(max-width: 600px) {
    .wrapper {
      grid-template-columns: 1fr;
    }
  }
