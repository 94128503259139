.tenten{
  display:block;
  text-decoration: underline;
}

.subscribePage{
  padding-bottom:56px;
  h1{
    font-family: 'Loos Normal';
    text-align: left;
    margin-top:74px;
    font-weight:400;
    font-size:72px;
    margin-bottom:0px;
  }
  .subscribeText{
    p{
      margin-top:22px;
      line-height: 32px;
      margin-bottom:0px;
      color:#000;
      font-size:32px;
      font-weight:400;
      font-family: 'MonumentGrotesk-Regular';
    }
    p.secondText{
      margin-top:10px;
    }
  }
  .subscribeRow{
    margin-top:84px;
  }
  .subscribeBox{
    position: relative;
    border-top:1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .inputsF{
      width:65%;
      p{
        font-size:16px;
        font-weight:400;
        font-family: 'MonumentGrotesk-Regular';
      }
      input{
        width:100%;
        border:0px;
        border-bottom:1px dashed #B3B3B3;
        font-weight:400;
        font-size:16px;
        height:23px;
        font-family: 'MonumentGrotesk-Regular';
        margin-bottom:24px;
      }
    }
    .subForm{
      margin-top:30px;
      width:50%;
    }
    ul,ol{
      padding-left:30px;
    }
    h2{
      font-size:32px;
      margin-top:16px;
      font-weight:500;
      font-family: 'MonumentGrotesk-Regular';
      margin-bottom:11px;
    }
    .description{
      width:65%;
      p{
        font-weight:400;
        font-size:16px;
        font-family: 'MonumentGrotesk-Regular';
        line-height: 24px;
      }
      li{
        line-height: 24px;
        font-family:'MonumentGrotesk-Regular';
        font-size:16px;
        font-weight:400;
      }
    }
    .padL{
      margin-bottom:77px;
    }
    .padL.withoutMarg{
      margin-bottom:0px;
    }
    .price{
      p{
        font-size:16px;
        margin-bottom:0px;
        color:black;
        font-weight:400;
        font-family: 'MonumentGrotesk-Regular';
      }
      span{
        font-weight:500;
        font-family: 'MonumentGrotesk-Regular';
        font-size:32px;
        line-height: 32px;
      }
    }
    button{
      transition:0.5s all;
      margin-top:9px;
      width:100%;
      height:48px;
      line-height: 50px;
      display:block;
      border:1px solid black;
      background:transparent;
      color:black;
      font-weight:500;
      font-size:12px;
      font-family: 'MonumentGrotesk-Regular';
      &:hover{
        background:black;
        color:white;
      }
    }
    button.activeSub{
      background:black;
      color:white;
    }
    .closeActiveSub{
      float:right;
    }
  }
  .subscribeBox{
    transition:0.5s all;
  }
  .subscribeBox.active,.subscribeBox:hover{
    background:black;
    .padL{
      padding-left:10px;
    }
    .price p{
      padding-left:10px;
    }
    li,.price p{
      color:white;
    }
    h2,.description p,.price span{
      color:white;
    }
    .price span{
      padding-left:10px;
    }
    button{
      color:white;
      border-top:1px solid white;
    }
  }
  .opacityH{
    position:absolute;
    top:-1px;
    left:0;
    width:100%;
    height:calc(100% + 1px);
    background:rgba(255,255,255,0.9);
  }
}

@media screen and (max-width: 500px) {
  .subscribePage .subscribeBox .description,.subscribePage .subscribeBox .inputsF{
    width:80%;
  }
  .subscribePage .subscribeText p{
    margin-top:10px;
    font-size: 16px;
    line-height:20px;
  }
  .subscribePage .subscribeRow{
    margin-top:28px;
  }
  .subscribePage .subscribeBox{
    margin-bottom:40px;
    height:unset;
  }
  .subscribePage .subscribeBox .padL{
    margin-bottom:37px;
  }
}
@media screen and (max-width: 600px) {
  #main .info-section .info-wrapper .info-row{
    display:block;
    text-align: left;
    .info-head{
      float:left;
    }
    .info-description{
      text-align: left;
    }
  }
}



.info-section {
  background-color: #f4f2f1;
}
.info-wrapper {
  padding: 15px 0 20px;
  text-align: center;
  padding-top:90px;
  padding-bottom:90px;
}
.info-row {
  display: flex;
}
.info-col {
  display: block;
  -webkit-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  padding: 5px;
  padding-left: 50px;
  padding-right: 50px;
  
  @media only screen and (max-width: 500px) {
    padding-left:0px!important;
  }
}
.info-a {
  display: block;
  font-size:14px;
  margin: 5px 0;
  text-decoration: none;
  text-align: center;
}
.info-head {
  display: -webkit-flex;
  -webkit-align-items: baseline;
  -webkit-justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
}
.info-head span {
  font-weight: 500;
}
.info-icon {
  margin-right: 15px;
}
.info-icon svg {
  width: 1em;
  height: 1em;
  font-size: 24px;
  fill: currentColor;
  vertical-align: middle;
}
.info-description {
  display: block;
  margin-top: 10px;
  font-weight: 400;
font-size: 14px;
}

.russia-section {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: red;
  padding-top: 30px;
  padding-bottom: 30px;
}

.collection-title {
  justify-content: center;
  font-size: 20px;
  font-weight: 250;
  margin-top: 52px;
  margin-bottom:52px;
  text-transform: uppercase;
  div{
    font-weight:400;
  }
}

.logos div{
  filter:grayscale(1);
  transition:all 500ms ease-in-out;
}

.logos div:hover{
  filter:grayscale(0);
}

@media only screen and (min-width: 768px) {
  .collection-title {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .collection-title {
    margin-top:24px;
    margin-bottom:24px;
  }
}
.contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  .item {
    width: 100%;
    padding: 0.5em;
    &.br {
      border-bottom: 0.5px solid black;
    }
  }
}

.contact-heading {
  font-weight: 500;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
}

.contact-details {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}
.contact-details p {
  padding: 3px;
}
.contact-details b {
  font-weight: 400;
}

.map-image {
  margin-top: 33px;
  width: 95%;
  text-align: center;
  border-radius: 15px;
}
