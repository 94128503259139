@import 'fonts.css';
.carousel-control-prev,.carousel-control-next,.carousel-indicators{
    display:none;
  }
  .mobileView{
    display:none;
  }
.Toastify{
    display:none;
}
.mMobile{
    display:none;
}
ul{
    list-style:none;
}
.hide{
    display:none;
}
.row{
    --bs-gutter-x:10px!important;
}
.ra-share-list{
    padding:0px;
}
.footerBg{
    position:relative;
    z-index: 6;
    background-color: white;
}
.plus {
    span{
        transition:0.5s all;
    }
    svg {
        width:100%;
        height:100%;
      rect {
        fill: #F7F7F7;
        transition:0.5s all;
      }
      path{
        stroke: black;
        transition:0.5s all;
      }
    }
  }
  .customPl{
    height:352px;
    transition:0.5s all;
    display:flex;
    align-items: center;
    justify-content: center;
    
    text-align: center;
    background: #F7F7F7;
    svg{
        width:112px;
        height:112px;
    }
    &:hover{
        background: black;
    }
  }
  .addArtworkbox{
    padding-top:112px;
    padding-bottom:100px;
    text-align: center;
    transition:0.5s all;
    svg{
        width:112px;
        height:112px;
        path{
            transition:0.5s all;
        }
    }
    &:hover{
        background:black;
        span{
            color:white;
        }
        svg{
            path{
                stroke:white;
            }
        }
    }
  }
.plusHover{
    cursor:pointer;
    input:hover ~ .plus svg rect{
        fill:black;
    }
    input:hover ~ .plus svg path{
        stroke:white;
    }
    input:hover ~ .customPl{
        background:black;
    }
}
.plus:hover{
    svg rect{
        fill:black;
    }
    svg path{
        stroke:white;
    }
    span{
        color:white;
    }
}
.trendMenu{
    margin-top:62px;
    overflow:hidden;
    padding-left:0px;
    height:43px;
    .lastPos{
        letter-spacing: 1px;
        span{
            //margin-left:15px;
        }
    }
    li,div{
        float:left;
        width:50%;
        a,span{    
            text-decoration: none;
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            color: #000000;
            opacity: 0.2;

        }
        span{
            font-size:16px;
            span{
                text-decoration:underline;
            }
        }
    }

    li.active{
        a,span{
            opacity:1;
        }        
    }
}
.curatedArtworks{
    font-size:32px!important;
}
.productGrid .col-4{
    padding-left:5px;
    padding-right:5px;
}

.trendMenu.fullWidth{
    width:100%;
    li{
        width:100%;
    }
}

span.frameIcon{
    width:14px;
    height:14px;
    display:inline-block;
    margin:auto;
    cursor:pointer;
    background:url('../assets/icons/frame.svg');            
    transition: all 0.3s ease;
}
span.frameIcon.active{
    background:url('../assets/icons/frameHover.svg');
}
span.shareIcon{
    width:14px;
    height:14px;
    display:inline-block;
    margin:auto;
    cursor:pointer;          
    transition: all 0.3s ease;
    background:url('../assets/icons/shareIcon.svg');       
}
.productBlock{
    position: relative;
    overflow:hidden;
    margin-bottom:24px;
    a{
        display:block;
    }
    .edit, .remove{
        position:absolute;
        opacity:0;
        transition:all 0.5s;
        right:8px;
        top:8px;
        cursor:pointer;
    }
    .edit{
        right:32px;
    }
    img.productImage{
        width:100%;
        margin-bottom:10px;
    }
    
    div.frame{
        float:left;
        margin-right:10px;
        cursor:pointer;
        height:46px;
        position:relative;
        text-align: center;
        cursor:pointer;
        display:block;
        width:100%;
        .frametext{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-feature-settings: 'salt' on;
            color: #000000;
            display:block;
            opacity:0;
            position:absolute;
            bottom:0;
            width:100%;
            text-align: center;
            transition: all 0.3s ease;
        }
       
    }
    div.frame:hover, div.frame.active{
        span.frameIcon{
            background:url('../assets/icons/frameHover.svg');
        }
        .frametext{
            opacity:1;
        }
    }

    div.author{
        position: relative;
        float:left;
        padding-left:5px;
        p,a{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            height:16px;
           // overflow:hidden;
            line-height: 16px;
            color: #000000;
            margin:0px;
        }
        a{
            text-decoration:none;
        }
        a:hover{
            text-decoration:underline;
        }
        p.size{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #CCCCCC;
        }
    }
    span.price{
        line-height: 16px;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #000000;
        display:block;
    }
}
.productBlock:hover{
    .edit,.remove{
        opacity:1;
    }
}
.newsletterForm{
    max-width:276px;
}
.comeSee{
    max-width: 171px;
}
.content{
    padding-left:10px;
    padding-right:10px;
}
#forStickyPos.row, #forStick2.row{
    position:sticky;
    background:white;
    z-index: 1;
    top:0px;
    width:calc(100% + 15px);
    padding-right:5px;
}
.productGrid .auctionPageShow{
    padding-top:0px;
}

#forStickyPos.row.sticky, #forStick2.row.sticky{
    width:100%;
    z-index: 10;
    position:fixed;
    top:30px;
    background-color: white;
    .trendMenu{
        margin-top:0px;
    }
}
.MuiSlider-valueLabel{
    display:none!important;
}
.trendBlock.active{
    a{
        color:black!important;
        opacity:1;
    }
}
.for3Col{
    div.frame{
        margin-right:0px;
    }
    .productBlock{
    span.price{
        font-size:16px;
        line-height: 20px;
    }
    }
}
.for3Col .productBlock div.author p,.for3Col .productBlock div.author a{
    font-size:16px;
    line-height: 20px;
}
.for3Col .productBlock div.author p.size{
    font-size:12px;
    line-height: 20px;
}
.stSecond{
    padding-top:110px;
}
#galP{
    padding-top:117px;
}
.nextPage{
    a::after{
        right:0;
    }
}
.centerPage{
    a::after{
        left:calc(50% - 55px);
    }
    a:hover::after{
        left:0;
    }
}
.prevPage{
    a::after{
        left:0;
    }
}
.productPaginate{
    a{
        min-width:100%;
        border-width:109px;
        text-align: left;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-decoration:none;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #000000;
        display:inline-block;
        transition: all 0.7s ease;
        padding:10px 38px;
        position:relative;       
    }
    a::after{
        position:absolute;
        transition: all 0.7s ease;
        bottom:0;
        min-width:109px;
        content:' ';
        border-bottom:1px solid #000;
    }

    a:hover::after{
        min-width:100%;
    }
    .prevPage a{
        cursor:pointer;
        float:left;
    }
    .centerPage{
        text-align: center;
        a{
            text-align: center;
        }
    } 
    .nextPage a{
        float: right;
        text-align: right;
        cursor:pointer;
    }
}
.allMenu{
    li,div{
        width:15%;
        text-align: center;
    }

    li:first-child,div:first-child{
        text-align: left;
        width:8%;
    }
    li:last-child,div:last-child{
        text-align: right;
        width:17%;
    }
}

.searchInput{
   position:relative;
   padding-right:10px;
   height:32px;
    .search{
        width:100%;
        height:32px;
        padding-top:10px;
        border:0px;
        padding-right:20px;
        border-bottom:1px solid rgba(0, 0, 0, 0.05);

        color: #CCC;
font-feature-settings: 'salt' on;
font-family: 'MonumentGrotesk-Regular';
font-size: 12px;
font-style: normal;
font-weight: 500;
    }   
    img{
        cursor:pointer;
        position:absolute;
        right:0;
        top:0;
    } 
}
.groupItems{
    position: relative;
}
.loginPopup,.signUpPopup,.forgotPopup{
    transition:0.5s all;
    background:#fff;
    position:absolute;
    display:none;
    flex-direction: column;
    padding:10px;
    justify-content: space-between;
    height:479px;
    width:100%;
    .popupHead{
        display: flex;
        flex-direction: column;
    }
    h1{
        max-width: 145px;
        color: #000;
        text-align: left;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */

        margin-bottom:0px;
        .closePopup{
            cursor:pointer;
            position:absolute;
            top:5px;
            right:0;
        }
    }
    input{
        color: #000;
        margin-top:12px;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        height:23px;
        border:0px;
        border-bottom: 1px dotted #B3B3B3;
    }
    label input{
        border:0px;
        width:1px;
    }
    .forgot{
        margin-top:4px;
        color: #B3B3B3;
        text-align: right;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        text-decoration-line: underline;
    }
    button.submit{
        height:40px;
        line-height: 40px;
   
        border:0px;
        width:100%;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        color: #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.72px;
        background:transparent;
        text-transform: uppercase;
        transition:0.5s all;
        &:hover{
            background:black;
            color:white;
        }
    }
    .dontH{
        margin-top:12px;
        margin-bottom:25px;//32
        text-align: center;
        color: #B3B3B3;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        transition:0.5s all;
        line-height: 16px; /* 133.333% */
        a{
            color: #B3B3B3;
            &:hover{
                color:black;
            }
        }
    }
    p.or{
        color: #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        margin-bottom:8px;
        line-height: 16px; /* 133.333% */
    }
    ul{
        padding:0px;
        list-style:none;
        display:flex;
        flex-direction: row;
        justify-content: center;
        //justify-content: space-between;
    }

    p.text{
        color: #CCC;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
    }
    p.text.red{
        color:#F556F5;
    }
}

.auctionPage{
    padding-left:10px;
    padding-right:10px;
    h1{
        margin:0px;
        text-align: left;
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'Loos Normal';
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px; /* 100% */
        margin-top:76px;
        margin-bottom:252px;
    }

}
.upcomingBlock{
    margin-bottom:54px;
}
.auctionBlocks{
    h2{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 100% */
        margin-bottom:26px;
    }
    .aucBlock{
        text-decoration: none;
    }
    .auctionTitle{
        display: block;
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'Loos Normal';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 100% */
    }
    img{
        display:block;
        margin-bottom:8px;
    }
    .date{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
    .arrow{
        float:right;
        width:55px;
        height:55px;
        border-radius: 30px;
        border: 1px solid #000;

        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'Loos Normal';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; 
        display:flex;
        justify-content: center;
        align-items: center;
        transition:0.5s all;
        img{
            margin-bottom:0px;
            width:30px;
            height:10px;
        }
    }
    .aucBlock:hover{
        .arrow{
            background:black;
            color:white;
            svg path{
                fill:white;
            }
        }
    }
}
.upComing{
    display: block;
    margin-bottom:17px;
    img{
        height:400px;
        object-fit:cover;
    }
}
.previousBlock{
    display:block;
    margin-bottom:38px;
    img{
        height:262px;
        object-fit: cover;
    }
}
.auctionPageShow{
    padding-left:10px;
    padding-right:10px;
    padding-top:76px;
    
    .auctionTitleShow{
        h1{
            max-width: 588px;
            text-align: left;
            color: #000;
            font-feature-settings: 'salt' on;
            /* h1-loos */
            font-family: 'Loos Normal';
            font-size: 72px;
            font-style: normal;
            font-weight: 400;
            line-height: 72px; /* 100% */
            margin-bottom:32px;
        }
    }
    .text{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
    .location{
        color:  #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-top:10px;
    }
    .download{
        margin-top:10px;
        text-decoration:none;
        color: #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        border-top:1px solid #000;
        border-bottom:1px solid #000;
        height:24px;
        display:block;
        line-height:24px;
        transition:0.5s all;
        &:hover{
            color:white;
            background:black;
        }
    }
    .auctionMargin{
        margin-top:63px;
    }
    .desc{
        color: #000;
        font-feature-settings: 'salt' on;
        /* h2-loos */
        font-family: 'Loos Normal';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 100% */
    }
    .sort{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        opacity:0.2;
        cursor:pointer;
        transition:0.5s all;
        &::selection{
            background:transparent;
        } 
        .arrow{
            &::selection{
                background:transparent;
            } 
        }  
    }
    .sort.active{
        opacity:1;
    }
    .artworkAuction{
        margin-bottom:10px;
    }
    .lotNum{
        display:block;
        color: #CCC;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    .author{
        color: #000;
        display:block;
        font-feature-settings: 'salt' on;
        font-family:  'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        a{
            text-decoration: none;
            &:hover{
                color:black;
                text-decoration: underline;
            }
        }
    }
    .artworkName{
        display:block;
        color: #000;
        font-feature-settings: 'salt' on;
        font-family:  'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    .estimate{
        display:block;
        color: #CCC;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    .price{
        display: block;
        color: #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
    }
    .sold{
        display:block;
        color: #D9D9D9;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    .lastBlockSold{
        margin-bottom:24px;
    }
    .bullet{
        display:block;
        color: #CCC;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
    }
    .onSale{
        display: block;
        color: #BD00FF;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-bottom:24px;
        line-height: 16px; /* 133.333% */
    }
}

@media only screen and (max-width: 991px) {
    .forgotPopup, .loginPopup, .signUpPopup{
        height:auto;
        z-index: 100;
    }
    .auctionPage h1{
        margin-bottom:238px;
    }
    .auctionBlocks .auctionTitle{
        font-size:24px;
    }
    .auctionBlocks .date{
        font-size:16px;
    }
    .auctionBlocks .arrow{
        float:left;
        margin-top:8px;
    }
    .upcomingBlock{
        margin-bottom:88px;
    }


}

.decorationNone{
    text-decoration: none;
}
.profileName{
    color: #000;
    text-align: center;
    position: absolute;
    top:9px;
    margin-left:5px;
    font-feature-settings: 'salt' on;
    font-family: 'MonumentGrotesk-Regular';
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}

.flexing{
    display:flex;
    flex-wrap: nowrap;
}
.flexEnd{
    justify-content: end;
}

.loginPopup.active, .signUpPopup.active, .forgotPopup.active{
    display:flex;
}
.curatorChoiceHide{
    display:none;
}
.float-right{
    float:right;
}

.searchPage{
    padding-top:74px;
    padding-left:8px;
    padding-right:8px;
    h1{
        font-family: 'Loos Normal';
        letter-spacing: -1px;
        font-style: normal;
        font-weight: 400;
        font-size: 72px;
        text-align: left;
        line-height: 72px;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-bottom:160px;
    }
    h2{
        font-family: 'Pragmatica';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 24px;        
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-bottom:31px;
    }
    .searchArtworks{
        margin-top:96px;
    }
    a{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-feature-settings: 'salt' on;
        color: #000000!important;
        text-decoration: none;
    }
    a:hover{
        text-decoration:underline;
    }
}
.logos{
    padding-left:10px;
    padding-top:64px;
    img{
        max-height:52px;
    }
    .slick-slide{
        height:52px;
        padding:0px;
    }

}
.curat{
    min-height:16px;
}

.passiveGrid{
    display:none;
}
.pageHeading{
    font-family: 'Loos Normal';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    font-feature-settings: 'salt' on;
    color: #000000;
    text-align: left;
    padding-top:106px;
    padding-left:8px;
}
.searchFArtist{
    margin-top:230px;
    padding-left:10px;
    padding-right:10px;
    width:100%;
    position:relative;
    button{
        width:28px;
        height:28px;
        background:url('../assets/icons/searchIcon.svg')no-repeat;
        border:0px;
        cursor:pointer;
        position:absolute;
        z-index: 1;
        bottom:8px;
    }
    input{
        padding-left:32px;
        position:relative;
        border:0px;       
        border-bottom: 1px solid #000000;
        width:100%;
        font-family: 'Loos Normal';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 32px;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
}
.curator_page{
    padding-top:96px;
    .links{
        padding-top:37px;
    }
    h1{
        
    margin-bottom:15px;
    color: #000;
    font-feature-settings: 'salt' on;
    font-family: 'Loos Normal';
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    text-align: left;
    }
    .text{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family:'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
   
    .statement{
        color: #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration:none;
        border-top:1px solid #000;
        display:block;
        padding-top:4px;
        padding-bottom:4px;
        border-bottom:1px solid #000;
        transition:0.5s all;
        width:100%;
    }
    .statement:hover{
        background:#000;
        color:#fff;
    }
    .socials{
        padding:0px;
        display:block;
        overflow:hidden;
        margin:0px;
        li{
            float:left;
            margin-right:12px;
        }
        li:last-child{
            margin-right:0px;
        }
    }
    .siteLink{
        position: relative;
        top:2px;
        text-decoration: none;
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
  }
  .curatedArtworks{
    padding-left:15px;
    padding-right:15px;
    h1{
        color: #000;
        text-align: left;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }
  }
.artistsPage{
    padding-top:16px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:105px;
    .group{
        margin-bottom: 24px;//0
    }
    a{
        text-decoration: none;
        font-family: 'MonumentGrotesk-Regular';
        font-weight: 400;
font-size: 16px;
line-height: 20px;

font-feature-settings: 'salt' on;


color: #000000;
    }
}
.ibioArtworks{
    h2{
        font-family: 'Pragmatica';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 24px;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-top:64px;
        margin-bottom:10px;
    }
}
.artistFullPage{
    padding-left:10px;
    padding-top:74px;
    .bioText div{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
    .bio{
        h1{
            font-family: 'Loos Normal';
            letter-spacing: -1px;
            font-style: normal;
            font-weight: 400;
            font-size: 72px;
            line-height: 72px;
            font-feature-settings: 'salt' on;
            color: #000000;
            max-width: 33.33%;
            text-align: left;
        }
      
        .bioImg{
            width:109px;
            height:109px;
            margin-right:129px;
            object-fit:cover;
        }
    }
    h2{
        font-family: 'Pragmatica';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 24px;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-bottom:10px;
    }

}
.ibioArtworks{
    padding-left:10px;
    padding-right:10px;   
}
.shopSearching{
    padding-left:10px;
    padding-top:38px;
    gap:10px;
    padding-right:10px;
    display:flex;
    justify-content: space-between;
    align-items: end;
    ul{
        padding:0px;
        margin:0px;
    }
    li{
        margin-top:16px;
        text-transform: lowercase;
        font-family: 'Loos Normal';
        font-style: normal;
        font-weight: 400;
        cursor:pointer;
        font-size: 32px;
        line-height: 32px;
        font-feature-settings: 'salt' on;
        color: #000000;
        opacity: 0.1;
    }
    li:first-child{
        margin-top:0px;
    }
    li.active{
        opacity:1;
    }
    .searchBox{
        border-bottom:1px solid #000;
        padding-right:20px;
        padding-bottom:9px;
        width:16.66%;
    }
    .searchTitle{
        width:33.32%;
        input{
            position:relative;
            top:2px;
            border:0px;
            font-family: 'Loos Normal';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            font-feature-settings: 'salt' on;
            color: #000000;
            width:calc(100% - 40px);
        }
        input::placeholder{
            opacity:0.2;
        }

        button{
            position:relative;
      
            width:24px;
            height:24px;
            background:url('../assets/icons/searchIcon.svg')no-repeat;
            border:0px;
            margin-right:15px;
        }
    }
    .searchYears{
        width:25%;
    }
}

.collectionNameforArt{
    position:absolute;
    top:5px;
    left:5px;
    color:black;
    font-size:12px;
    font-weight:500;
    background:white;
    padding-left:4px;
    padding-right:4px;
    font-family:'MonumentGrotesk-Regular';
    line-height: 16px;
    height:16px;
}

.MuiSlider-thumb{
    border-radius:0px!important;
    width:1px!important;
    height:15px!important;
    margin-left:0px!important;
    margin-right:0px!important;
    border-right:0px!important;
    margin-top:-7.5px!important;
}
.MuiSlider-markLabel{
    top:-25px!important;
    font-family: 'Loos Normal'!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 32px!important;
    line-height: 32px!important;
    font-feature-settings: 'salt' on;
    color: #000000;
    transform:translateX(-5px)!important;
    display:none;
}

.slider_values{
    span{
        top:22px;
        position: relative;
        font-family: 'Loos Normal'!important;
        font-style: normal;
        font-weight: 400!important;
        font-size: 32px!important;
        line-height: 32px!important;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
    span.start{
        float:left;
        left:-4px;
    }
    span.end{
        float:right;
        right:-4px;
    }
}
.MuiSlider-markLabel:first-child{
    left:25px!important;
}
.MuiSlider-marked{
    margin-bottom:0px!important;
    padding-bottom:0px!important;
    top:16px!important;
}
.MuiSlider-rail,.MuiSlider-track{
    height:0px !important;
}
.searchYears{
    padding-left:5px;
    padding-right:5px!important;
}
.searchPrice{
    input{
        width:100%;
        border:0px;
        font-family: 'Loos Normal';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 32px;
        font-feature-settings: 'salt' on;
        color: #000000;
        opacity: 0.2;
        position: relative;
        top:7px;
    }
}
.searchBox.clear{
    padding-bottom:0px;
    button{
        font-family:'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 41px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        color: #000000;
        display:block;
        width:109px!important;
        height:41px;
        border:0px;
        cursor:pointer;
        background:transparent;
        transition: all 0.5s;
        border-top:1px solid black;
    }
    button:hover{
        background-color:black!important;
        color:white;
    }
    width:109px!important;
}
.marginsRight{
    margin-right:0px;
    margin-left:0px;
}
.shopG{
    padding-left:10px;
    padding-right:10px;
    padding-top:23px;
}
.textPage{
    padding-top:88px;
    padding-left:8px;
    min-height:calc(100vh - 350px);
    ul{
        padding:0px;
    }
    h1{
        font-family: 'Loos Normal';
        font-style: normal;
        font-weight: 400;
        letter-spacing: -1px;
        font-size: 72px;
        line-height: 72px;        
        font-feature-settings: 'salt' on;        
        color: #000000;
        text-align: left;
        margin-bottom:41px;
    }
    .text, .text p, .text li{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
}
.specialProjects{
    padding-top:72px;
    .forPad{
        margin-bottom:100px;
    }
    h1{
        color: var(--black, #000);
font-feature-settings: 'salt' on;
font-family: 'Loos Normal';
font-size: 72px;
font-style: normal;
font-weight: 400;
margin-bottom:7px;
line-height: 72px; /* 100% */
text-align: left;
    }
    p{
        margin:0px;
        color: #000;
font-feature-settings: 'salt' on;

/* text-16 */
font-family: 'MonumentGrotesk-Regular';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
    }
    .dir{
        flex-direction: column;
    }
    .text{
        margin-top:48px;
        color:#000;
font-feature-settings: 'salt' on;

/* text-16 */
font-family: 'MonumentGrotesk-Regular';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
    }
}
.galleryShow .carousel{
    img{
        height:calc(100vh - 150px);
        object-fit: cover;
    }   

}
.productShow{
    padding-top:72px;
    padding-left:10px;
    padding-right:10px;
    .prod{
        display:flex;
        align-items: center;
    }
    .main-pic{
        max-height:calc(100vh - 70px);
        max-width: 100%;
    }
    .picsNum{
        width:8.3%;
        text-align: center;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-right:10px;
        .borderT{
            width:100%;
            display:block;
            height:1px;
            border-top:1px solid black;
            margin-top:8px;
            margin-bottom:8px;
        }
        button{
            display: block;
            width: 100%;
            border: 0px;
            background: transparent;
        }
    }

    .prodPic{
        margin-right:9px;
        width:66.64%;
        text-align: center;
    }
    .prodDetails{
        width:33%;
        height:calc(100vh - 70px);
        .title{
            position:relative;
            height:50%;
            ul{
                padding:0px;
                margin:0px;
                position: absolute;
                bottom:0;
                padding-bottom:9px;
                width:100%;
                border-bottom:1px solid black;
                li{
                    float:left;
                    width:50%;
                    a{
                        text-decoration: none;
                        font-family: 'MonumentGrotesk-Regular';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        font-feature-settings: 'salt' on;
                        color: #000000;
                        display:block;
                        span{
                            float:left;
                            margin-right:9px;
                        }
                    }
                }
            }
        }
        p{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-feature-settings: 'salt' on;
            color: #000000;
            margin-bottom:4px;
        }
        p.withoutMargin{
            margin-bottom:0px;
        }
        h1{
            margin-top:4px;
            font-family: 'Loos Normal';
            letter-spacing: -1px;
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            font-feature-settings: 'salt' on;
            color: #000000;
            text-align: left;
            margin-bottom:2px;
        }
        a.galleryTitle{
            font-size:12px;
            line-height: 16px;
            display: block;
            text-decoration: none;
            color:#B3B3B3;
            font-family: 'MonumentGrotesk-Regular';
            margin-bottom:8px;
        }
        .size{
            float:left;
            width:50%;
        }
        .size{
            p{
                margin-bottom:0px;
            }
        }
        .orig{
            margin-top:16px;
        }
    }

    .description{
        height:50%;
        padding-top:8px;
        position:relative;
        .text{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-feature-settings: 'salt' on;
            color: #000000;
        }
        ul{
            padding:0px;
            margin:0px;
            position:absolute;
            bottom:0;
            width:100%;
            li{
                float:left;
            }
            li:first-child{
                text-align: center;
                width:30%;
                margin-right:10px;
            }
            li:last-child{
                width:calc(70% - 10px);
                button{
                    background:transparent;
                    border:0px;
                    font-family: 'MonumentGrotesk-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                    font-feature-settings: 'salt' on;
                    color: #000000;
                    border-top:1px solid black;
                    border-bottom:1px solid black;
                    cursor:pointer;
                    width:100%;
                    display:block;
                    padding-top:13px;
                    padding-bottom:13px;
                }
            }
            .price{
                font-family: 'MonumentGrotesk-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                margin-bottom:0px;
                font-feature-settings: 'salt' on;
                color: #000000;
                padding-top:13px;
                padding-bottom:14px;
                border-bottom:1px solid black;
                .currency{
                    position: relative;
                    top:1px;
                    margin-right:5px;
                }
            }
        }
    }
}
h1.related{
    text-align: left;
    font-family: 'Pragmatica';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    padding-top:64px;
    color: #000000;
    margin-bottom:10px;
}
.paddingLeft{
    padding-right:10px;
    padding-left:10px;
}
.galleryPage{
    padding-top:74px;
    padding-bottom:8px;
    .galleryDesc{
        margin-bottom:112px;
        h1{
            text-align: left;
            font-weight: 400;
            font-size:72px;
            line-height: 72px;
            font-family: 'Loos Normal';
            margin-bottom:6px;
        }
        p{
            max-width: 877px;
            font-weight:400;
            font-size:16px;
            line-height: 20px;
            font-family: 'MonumentGrotesk-Regular';
        }
        h2{
            font-weight:400;
            font-size:32px;
            line-height:80px;
            font-family: 'MonumentGrotesk-Regular';
            height:72px;
        }
        .subSide{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p{
                max-width: 75%;
                font-weight: 400px;
                font-size:16px;
                line-height: 20px;
                font-family: 'MonumentGrotesk-Regular';
            }
            a{
                display: block;
                text-align: center;
              text-decoration: none;
                border:1px solid black;
                height:48px;
                font-family: 'MonumentGrotesk-Regular';
                font-size:12px;
                font-weight:500;
                line-height:48px;
                transition:0.5s all;
                &:hover{
                    background:black;
                    color:white;
                }
            }
        }
    }

    .galleryItem{ 
        margin-bottom:60px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        img{
            height:20vw;
        }
        .title{
            margin-top:21px;
            margin-bottom:15px;
            font-weight:400;
            font-size:32px;
            line-height:32px;
            font-family: 'Loos Normal';            
        }
        p{
            font-weight:400;
            font-size:16px;
            line-height:20px;
            font-family: 'MonumentGrotesk-Regular';
            max-width: 95%;
        }
        p.date,p.location,a.website{
            font-weight:400;
            font-size:16px;
            line-height:20px;
            margin-bottom:0px;
            font-family: 'MonumentGrotesk-Regular';
        }
        a.website{
            text-decoration: none;
        }
      
        a.full{
            margin-top:16px;
            display:block;
            text-align: center;
            border:1px solid black;
            text-decoration:none;
            height:56px;
            font-size:12px;
            line-height:56px;
            font-weight: 500;
            font-family: 'MonumentGrotesk-Regular';
            transition:0.5s all;
            &:hover{
                background:black;
                color:white;
            }
        }
    }
}
.galleryShow{
    padding-left:5px;
    h1{
        margin-bottom:63px;
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'Loos Normal';
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px; /* 100% */
        text-align: left;
    }
    .img{
        height:28px;
    }
    .socials{
        overflow:hidden;
        padding:0px;
    }
    .socials li{
        margin-right:11px;
        float:left;
    }
    .text{
        padding-left:5px;
    }
    .text p{
        font-weight:400;
        font-size:16px;
        line-height:20px;
        font-family: 'MonumentGrotesk-Regular';
    }
    .carousel.slide{
        width:100%;
    }
    .carousel-indicators{
        display:none;
    }
    .carousel-control-prev{
        position: absolute;
        left:0;
        width:50%;
        display: block;
        background:rgba(0,0,0,0.1);
        transition:0.5s all;
        .carousel-control-prev-icon{
            display:none;
        }
    }
    .carousel-control-next{
        transition:0.5s all;
        position: absolute;
        right:0;
        width:50%;
        display: block;
        background:rgba(0,0,0,0.1);
        .carousel-control-next-icon{
            display:none;
        }
    }
}
.galleryArtworks{
    margin-top:64px;
    h1{
        font-family: Loos Normal;
        font-weight:400;
        font-size:32px;
        line-height: 32px;
        margin-bottom:10px;
    }
}
.leftD{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.galleryShow .bottomBorder{
    border-bottom:1px solid black;
    height: 100%;
    img,.socials{
        margin-bottom:8px;
    }
    a,p{
        margin-bottom:0px;
        text-decoration: none;
        font-size:16px;
        line-height: 20px;
        font-weight:400;
        font-family: 'MonumentGrotesk-Regular';;
    }
    a:hover{
        color:black;
    }
}
.exhibition{
    margin-bottom:64px;
    padding-top:64px;
    .flex{
        justify-content: space-between;
        flex-direction: column;
    }
    h1{
        color: #000;
        font-feature-settings: 'salt' on;

        /* h2-loos */
        font-family: Loos Normal;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 100% */
        margin-bottom:12px;
    }
    p{
        color: var(--black, #000);
        font-feature-settings: 'salt' on;
        
        /* artwork-text-12 */
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        margin-bottom:0px;
    }
    .text{
        color: var(--black, #000);
        font-feature-settings: 'salt' on;
        margin-top:40px;

        /* artwork-text-12 */
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    .link{
        color:  #000;
        text-align: center;
        font-feature-settings: 'salt' on;
        width:109px;
        /* artwork-text-12 */
        font-family: 'MonumentGrotesk-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        text-decoration:none;
        border-top:1px solid #000;
        border-bottom:1px solid #000;
        transition:0.5s all;
        padding:3px 16px;
        &:hover{
            background:#000;
            color:#fff;
        }
    }
    .cap{
        position:absolute;
        bottom:0;
        right:5px;
        padding: 4px 10px 4px 10.5px;
        background: rgba(0, 0, 0, 0.30);
        span{
            display:block;
            color: var(--white, #FFF);
font-feature-settings: 'salt' on;

/* artwork-text-12 */
font-family: 'MonumentGrotesk-Regular';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
        }
    }
}
.fullgaller{
    padding-left:0px;
    padding-right:0px;
    padding-top:34px;
    border-bottom:1px solid #000;
    padding-bottom:27px;
    margin-left:10px;
    margin-right:10px;
    .flexDir{
        flex-direction: column;
        justify-content:space-between;
        padding-left:0px;
    }
    .text{
        color: #000;
        font-feature-settings: 'salt' on;
        /* text-16 */
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        p{
            margin-bottom:0px;
        }
    }
    .link{
        color: #000;
        font-feature-settings: 'salt' on;
        font-family: 'MonumentGrotesk-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
    .socials{
        margin:0px;
        overflow:hidden;
        padding:0px;
        li{
            float:left;
            margin-right:12px;
        }
        li:last-child{
            margin-right:0px;
        }
    }
}
h1.not_found{
    font-family: 'Loos Normal';
    letter-spacing: -1px;
    font-style: normal;
    font-weight: 400;
    font-size: 144px;
    line-height: 181px;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #000000;
}
p.no_page{
    font-family: 'Loos Normal';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1px;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #000000;
}
.profile{
    padding-top:72px;
    .bUpdate{
        margin-right:110px;
        position:relative;
        width:110px;
        input{
            cursor: pointer;
            position:absolute;
            top:0;
            left:0;
            height:110px;
            opacity:0;
        }
    }
    .profileBioUp{
        
        h1{
            font-family: 'Loos Normal';
            font-style: normal;
            letter-spacing: -1px;
            font-weight: 400;
            font-size: 72px;
            line-height: 72px;
            font-feature-settings: 'salt' on;
            color: #000000;
            max-width: 300px;
            text-align: left;
            width:33.32%;
        }
        textarea{
            width:100%;
            margin-left:10px;
            height:192px;
            resize:none;
            border:0px;
            background: #F7F7F7;
            padding:12px;

            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-feature-settings: 'salt' on;
            color: rgba(0,0,0,0.2);

        }
        button{
            width: 110px;
            height: 40px;
            border-width: 1px 0px;
            border-style: solid;
            border-color: #000000;
            background:transparent;
            display:block;
            margin-top:42px;
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            transition:0.5s all;
        }
        button:hover,button.active{
            background:black;
            color:white;
        }
    }
}
.square a:before{
    padding-top:30%;
    display:block;
    content:' ';
}
.square a:after{
    padding-top:40%;
    display:block;
    content:' ';
}
.addArtwork{
    background:#F7F7F7;
    text-align: center;
    position: relative;
    a{
        text-decoration:none;
        display:block;
        span{
            position:absolute;
            bottom:12px;
            display:block;
            width:100%;
            text-align: center;

            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-feature-settings: 'salt' on;
            color: #000000;
        }
    }
}
.details{

    .deliveryDetails{
        h1{
            text-align: left;
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            font-feature-settings: 'salt' on;
            color: #000000;
        }
  
    }
    input{
        display:block;
        background:transparent;
        border:0px;
        border-bottom: 1px dashed #B3B3B3;
        width:100%;
        margin-bottom:17px;
    }
    
    .contact-form.dashboard{
        width:100%;
        display:flex;
        justify-content: space-between;
        .form{
            width:57%;
        }
        .updateAddress{
            border-width: 1px 0px;
            border-style: solid;
            border-color: #000000;
            width:228px;
            max-height:224px;            
            letter-spacing: 0.72px;
            cursor:pointer;
            transition:0.5s all;
        }
        .updateAddress:hover{
            background:black;
            color:white;
        }
    }
}
.addToCart{
    transition:0.5s all;
}
.addToCart:hover{
    background:black!important;
    color:white!important;
}
.allMenu.inProfile{
    padding-left:0px;
    border-bottom:1px solid black;
}
.allMenu.inProfile li{
    width:20%;
}
.allMenu.inProfile li:first-child,.allMenu.inProfile li:last-child{
    width:20%;
}
.navbar-brand{
    margin-right:0px;
}
.navbar-nav{
    li:first-child{
        a{
            padding-left:10px!important;
        }
    }
}

.addPage{
    .addonImages{
    .pics .plus{
        text-align: center;
        background:#f7f7f7
    }
    .pics .plus img{
        max-height:98px;
    }
}

    .firstForm{
        padding-left:10px;
    }
    .plusHover{
        input{
            cursor:pointer;
        }
        position:relative;
    }
    .plus{
        position:relative;
        cursor:pointer;
        height:100%;
        img{
            max-width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .hiddenInput{
        position:absolute;
        width:100%;
        height:100%;
        opacity:0;
    }
    .labelName{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        max-width:348px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
    .labelName.smallSize{
        line-height: 16px;
        font-size:12px!important;
    }
    .picture{
        
          
        a{
            background:#F7F7F7; 
            position:relative;
            display:block;
            text-align: center;
            img{
                max-width:372px;
                max-height:352px;
            }
            input{
                position:absolute;
                top:0;
                width:100%;
                opacity:0;
                left:0;
            }
        }
    }
    .addonImages.picBox{
        margin-top:8px;
        justify-content: left;
        
        .picture{
            
         .pics{
            background:transparent;
         }
            a{
                margin-right:8px;
                width:119px;
                height:112px;
                line-height:112px;
                display:inline-block;
                background: #F7F7F7;
                img{
                    max-width:119px;
                    max-height:112px;
                }
            }
            a.lastM{
                margin-right:0px;
            }
        }
    }
}
.smallSize{
    margin-top:12px;
    font-size:12px!important;
}
.submitArtwork.dashboard{
    .labelName{
        margin-top:25px;
        margin-bottom:4px;
    }
   
    select{
        width:100%;
        border:0px;
        background: transparent;
        border-bottom: 1px dashed #B3B3B3;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
 
        color:black;
    }
    .plus{
        input{
            cursor:pointer;
        }
    }
    select::placeholder, input::placeholder, textarea::placeholder {
        color: #B3B3B3;
        opacity: 1;
      }
    input{
        background:transparent;
        border:0px;
        border-bottom: 1px dashed #B3B3B3;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        width:100%;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
        color: #000;
    }
    textarea{
        background:#F7F7F7;
        border:0px;
        padding:8px;
        height:160px;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        width:100%;
        line-height: 16px;
        font-feature-settings: 'salt' on;
        color: #000;
    }
}
.relative{
    position:relative;
}
.cm{
    position:absolute;
    right:10px;
    top:0;
    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    font-feature-settings: 'salt' on;
    color: #B3B3B3;
}
.fees{
    border-bottom:1px solid black;
    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    font-feature-settings: 'salt' on;
    color: #B3B3B3;
    span{
        float:right;
        font-family: 'MonumentGrotesk-Regular';
        font-size:16px;
        color:#000;
    }
    .totalText{
        font-size:16px;
        top:-25px;
        color:#000;
    }
}
.submitArtworkButton{
    border:0px;
    width: 100%;
    height: 40%;
    border-top:1px solid #000000;
    border-bottom:1px solid #000000;
    background: transparent;

    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-feature-settings: 'salt' on;
    color: #000000;
    transition:0.5s all;
}
.submitArtworkButton:hover{
    color:white;
    background:black;
}
.noMargin{
    margin-top:0px!important;
}
.cartPage{
    padding-left:7px;
    padding-right:7px;
    padding-top:74px;
    h1{
        font-family: 'Loos Normal';
        letter-spacing: -1px;
        font-style: normal;
        font-weight: 400;
        font-size: 72px;
        line-height: 72px;
        font-feature-settings: 'salt' on;
        color: #000000;
        text-align: left;
    }
}
.shipInfo{
    padding-top:92px;
    input[type='text']{
        width:100%;
        display:block;
        background:transparent;
        border:0px;

        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-bottom:9px;
        padding-bottom:4px;
        border-bottom: 1px dashed #CCCCCC;
    }
    input.shippingCheckbox{
   
        
    }
    input[type='text']::placeholder{
        opacity:0.2;
    }
}

.checkbox_container {
    float:left;
    padding-right: 35px;
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmarkd {
    position: absolute;
    top: -2px;
    right: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox_container:hover input ~ .checkmarkd {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox_container input:checked ~ .checkmarkd {
    background-color: black;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmarkd:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox_container input:checked ~ .checkmarkd:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox_container .checkmarkd:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
.checkout{
    padding-top:100px;
    h1{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 32px;
        font-feature-settings: 'salt' on;
        color: #000000;
        margin-bottom:6px;
    }
    p{
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;        
        font-feature-settings: 'salt' on;                
        color: #000000;
        span{
            font-family: 'MonumentGrotesk-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            font-feature-settings: 'salt' on;
            color: #000000;
            float:right;
        }
    }
    p.total_amount{
        margin-top:10px;
        span{
            font-size:32px;
        }
    }
    .payNow{
        background:transparent;
        border:0px;
        margin-top:2px;
        border-width: 1px 0px;
        border-style: solid;
        border-color: #000000;
        width:100%;
        height:48px;

        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        color: #000000;

    }
}
.bioText{
    max-width:469px;
}
.bioText.fullWidth{
    display:none;
    .hidden{
        display:none;
    }
    .active{
        margin-top:20px;
    }
    .showMore{
        border:0px;
        margin-top:20px;
        width:157px;
        cursor:pointer;
        text-align: left;
        font-family: 'MonumentGrotesk-Regular';
        font-style: normal;
        font-weight: 400;
        background:transparent;
        border-bottom:1px solid black;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
        color: #000000;
    }
}
.forMobileBigArt{
    display:none;
}
.picsNum.mobile{
    display:none;
}
.grid-container-text .first, .grid-container-text .first p, .grid-container-text .first li{
    font-family: 'MonumentGrotesk-Regular';
    font-style: normal;
    font-weight: 400;
    background:transparent;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'salt' on;
    color: #000000;
}

@media only screen and (max-width: 1230px) {
  
    .slide .cert a .text{
        max-width:160px;
    }
    .artistFullPage .bio .bioImg{
        margin-right:8px;
    }
  
    .MuiSlider-markLabel{
        font-size:24px!important;
    }
}

@media only screen and (max-width: 1070px) {
   
   
    .trendMenu li a, .trendMenu li span, .trendMenu div a, .trendMenu div span{
        font-size:16px;//24
    }
    .searchYears{
        padding-left:12px;
    }
}
@media only screen and (max-width: 991px) {
   .loginPopup, .signUpPopup{
    width:210px;
   }
    .searchInput,.flexing,.actionButtons.LogOut,.profileName{
        display:none!important;
    }
    .galleryShow{
    img{
        height:auto;
    }    
}
    .bioText.fullWidth{
        display:block;
        margin-top:33px;
        max-width:100%;
    }
    .bioImg{
        float:right;
    }
    .smallHide{
        display: none;
    }
    #galP{
        padding-top:100px;
    }
    .row{
        --bs-gutter-x:8px!important;
    }
    .galleryPage .galleryItem .title{
        font-size:46px;
        line-height:46px;
    }
    .hideLg{
        display: none;
    }
    .hide_2{
        display:none;
    }
    .slide .cert a .text{
        max-width:200px;
    }
    .trendMenu li span{
        display:none;
    }
    .removeLg{
        display:none;
    }
    .productBlock div.frame{
        height:60px;
    }

    .shopSearching li{
        font-size:24px;
        line-height:24px;
    }
}
@media only screen and (max-width: 767px) {
    .addPage .addonImages{
        margin-bottom:33px;
    }
    .profile .profileBioUp textarea{
        margin-left:0px;
    }
    .addArtworkbox{
        padding-top:40px;
        padding-bottom:60px;
        svg{
            width:70px;
            height:70px;
        }
    }
    .addPage{
        .firstForm{
            padding-left:0px;
        }
    }
    .specialProjects {
        h1{
            font-size:32px;
        }
        .text{
            margin-top:24px;
            margin-bottom:40px;
        }
        .forPad{
            margin-bottom:63px;
        }
    }
    .mobileView{
        display:block;
    }
    .noMobile{
        display:none;
    }
    .galleryShow{
        .broch{
            margin-top:74px;
            padding-left:15px;
            padding-right:15px;
        }
        .fullgaller{
            padding-bottom:11px;
        }
        .mobileView{
            margin-top:40px;
        }
        h1{
            font-size:32px;
        }
        .socials{
            float:right;
            .link{
                float:left;
            }
        }
        .exhibition{
            .flex{
                display:block;
            }
            .link{
                margin-top:20px;
                float:right;
            }
        }
    }
    .curator_page{
        padding-left:10px;
        padding-right:10px;
        padding-top:56px;
        img.marginBottom{
            margin-bottom:112px;
        }
        h1{
            font-size:32px;
            line-height: 32px;
        }
        .curatedArtworks{
            padding-top:61px;
            padding-left:0px;
            padding-right:0px;
        }
    }
    .artistFullPage{
        padding-top:48px;
    }
  
   
    .galleryPage .galleryItem{
        flex-direction: column;
        .title,.text, a{
            width:100%;
        }
    }
    .profile .profileBioUp{
        flex-direction: column;
        h1,textarea{
            width:100%;
        }
        .bUpdate{
            margin-top:20px;
        }
    }
    .picsNum.mobile{
        display:block;
        position:absolute;
        top:10px;
        right:0;
        width:50px;
        span{
            float:left;
            width:5px;
            color:black;
            font-family: 'MonumentGrotesk-Regular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
text-align: center;
font-feature-settings: 'salt' on;
        }
        .borderT{
            border-top:0px;
            border-left:1px solid black;
            margin-left:19px;
            margin-right:19px;
            position:relative;
            top:-12px;
            width:1px;
            height:24px;
        }
    }
    .productShow .prod{
        flex-direction: column;
    }
    .picsNum{
        display:none;
    }
    .productShow{
        padding-top:35px;
        padding-left:0px;
        padding-right:0px;
        .prodPic{
            margin-right:0px;
            width:100%;
            .main-pic{
                width:100%;
                height:auto;
                max-height: unset;
            }
        }
        .prodDetails{
            padding-left:10px;
            margin-top:12px;
            padding-right:10px;
            width:100%;
            height:auto;
            .title ul{
                padding-top:53px;
                overflow:hidden;
                position: relative;
            }
        }
        .description{
            ul{
                position:relative;
                margin-top:100px;
                li:first-child,li:last-child{
                    width:100%;
                    display:block;
                }
                li:first-child{
                    .price{
                        border-bottom:0px;
                        .currency{
                            top:unset;
                        }
                    }
                }
            }
            // ul{
            //     position:fixed;
            //     bottom:16px;
            //     left:0;
            //     width:100%;
            //     background: white;
            //     z-index: 5;
            //     overflow:hidden;
            //     margin-top:64px;
            //     .price{
            //         border-top:1px solid black;
            //     }
            // }
        }
    }

    .relatedSection{
        padding-top:0px;
    }
  
    .shopSearching{
        flex-direction:column;
        align-items:start;
   
    }
    .shopSearching .searchBox{
        width:100%;
    }
    .shopSearching{
        button.search{
            margin-top:10px;
        }
        .searchTitle input{
            top:10px;
        }
        .searchYears{
            margin-top:20px;
        }
        .searchTitle{
            overflow:hidden;
        }
    }
    .forMobileBigArt{
        display:block;
    }
    .centerPos{
        text-align: center;
    }
    .lastPos{
        text-align: right;
        
    }
    .hideLast{
        display:none;
    }
    .trendMenu{
        height:auto;
    }
    .allMenu div{
        width:20%!important;
        text-align: left;
    }
    .footerPaddingRight{
        padding-right:61px;
    }
    .footerLineMargin{
        margin-bottom:20px;
    }
    footer a.download{
        float:right;
    }
    footer .secondLine{
        margin-top:30px!important;
        position: relative;
        .social-list{
            position:absolute;
            right:0;
            top:15px;            
            a:last-child{
                margin-right:0px;
            }
        }
        ul.footerMenu{
            margin-top:16px;
            li{
                margin-bottom:8px;
            }
            a{
                padding:0px 12px 0px 0px;
            }
        }
    
    }
    .newsletterForm,.comeSee{
        max-width: 95%;
        h4{
            float:left;
            width:114px;
        }
        .newsletterDiv,.seeText{
            width:calc(100% - 114px);
            float:right;
        }
        .subscribe{
            margin-top:10px;
            input,button{
                width:100%;
                position:relative;
            }
            button{
                top:-1px;
            }
        }
    }
    .newsletterForm{
        max-width: 100%;
    }

    .searchFArtist button{
        width:14px;
        height:15px;
        background-size:14px;
    }
    .searchFArtist input{
        font-size:16px;
        line-height:27px;
    }
}
footer .secondLine ul li a:hover{
    text-decoration: underline;
}

.prevCols{
    color: #000;
    font-feature-settings: 'salt' on;
    font-family: 'Loos Normal';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 100% */
    margin-top:479px;
    margin-bottom:30px;
    display:block;


}

.prevLinks{
    max-width: 126px;
    text-decoration: none;
    display:inline-block;
    margin-bottom:8px;
    margin-right:50px;
    color: rgba(0, 0, 0, 0.25);
    font-feature-settings: 'salt' on;
    font-family: 'MonumentGrotesk-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    span{
        display:block;
        color:#000;
    }
    &:hover{
        color:black;
    }
}
.mobLocation{
    display:none;
}
.displayHide{
    display:none;
}
@media only screen and (max-width: 550px) {
    .prevLinks{
        max-width: 133px;
    }
    .prevEvents{
        color: #000;
        display:block;
        margin-top:114px;
        margin-bottom:30px;
        font-feature-settings: 'salt' on;
        font-family: 'Loos Normal';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 100% */
    }
    .displayHide{
        display:block;
    }
    .auctionPageShow .auctionTitleShow h1{
        max-width: 238px;
        padding-left:15px;
        margin-bottom:0px;
        font-size:32px;
        line-height:40px;
    }
    .auctionPageShow{
        .artworkName{
            display:block;
            overflow: hidden;
        }
        .lotNum{
            font-weight:500;
        }
        .estimate,.sold{
            font-weight: 500;
        }
        .author,.estimate,.sold{
            position:relative;
            text-align: left;
            display: block;
            span{
                position:absolute;
                right:0;
                top:0;
            }
        }
        .priceSort{
            text-align: right;
        }
        .results{
            padding-bottom:15px;
            color: #000;
            font-feature-settings: 'salt' on;
            font-family: 'MonumentGrotesk-Regular';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 100% */
        }
        .mobLocation{
            margin-top:0px;
            display:block;
            padding-left:15px;
            font-size:16px;
            font-weight:500;
        }
        .text{
            padding-left:15px;
            font-size:16px;
            margin-top:33px;
            margin-bottom:47px;
        }
        .download{
            width:107px;
            margin-left:15px;
            margin-top:0px;
        }
    }
    .mobHide{
        display:none;
    }
    .upComing img{
        height:202px;
    }
    .upComing{
        margin-bottom:32px;
    }

    .allMenu.inProfile li:first-child,.allMenu.inProfile li:last-child{
        width:15%;
    }
    .inProfile a{
        font-size:12px!important;
    }
  
    .allMenu div{
        width:auto!important;
        padding-right:7px;
    }
    .productPaginate a{
        padding:10px 7px;
        min-width:40px;
    }
    .allMenu div:first-child{
        width:20%!important;
    }
   .nMobile{
    display:none;
   }
   .mMobile{
    display:block;
    margin-bottom:8px;
   }
   .for3Col .productBlock div.author p, .for3Col .productBlock div.author a,.for3Col .productBlock span.price{
        font-size:12px;
        line-height:14px;
   }
    // .productBlock div.frame {
    //     height: 30px;
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    // }
    // .productBlock div.frame.forTop {
    //     height:50px;
    // }
 
    
    .productBlock .price{
        position:relative;
    }
 
}
.closeMenu{
    position:absolute;
    cursor:pointer;
    z-index:200;
    padding:20px;
    right:15px;
}
.mobilePics,.downloadMobile{
    display:none;
}
.secondLine{
    padding-top:20px;
}

.mobileText,.mobileSub{
    display:none;
}

@media only screen and (max-width: 500px) {
    .productShow .prodDetails a.galleryTitle{
        background:black;
        color:white;
        display:inline-block;
        font-weight: 500;
        height:16px;
        padding-left:4px;
        padding-right:4px;
    }
    .galleryShow{
        padding-left:0px;
    }
    .galleryShow .galleryArtworks h1{
        margin-top:0px;
    }
    .galleryShow h1{
        line-height:32px;
        margin-top:24px;
        padding-left:10px;
        margin-bottom:17px;
    }
    .galleryShow .text p{
        font-size:16px;
        line-height:20px;
    }
    .galleryShow .mobPad{
        padding-left:10px;
        padding-right:10px;
    }
    .galleryPage .galleryItem .title{
        margin-top:12px;
        font-size:24px;
        line-height:24px;
        font-weight:500;
    }
    .galleryPage .galleryItem p{
        max-width: unset;
    }
    .galleryPage .galleryDesc{
        margin-bottom:64px;
    }
    .galleryPage .galleryItem img{
        height:unset;
        width:100%;
    }
    .mobileText,.mobileSub{
        display:block;
    }
    .desktopText,.desktopSub{
        display:none;
    }
    .galleryPage .galleryDesc h1{
        margin-bottom:10px;
    }
    .galleryPage .galleryDesc p{
        font-size:16px;
        line-height: 20px;
    }
    .galleryPage .galleryDesc h2{
        height:unset;
        font-size:32px;
        line-height: 32px;
        margin-top:24px;
        margin-bottom:9px;
    }
    .galleryPage .galleryDesc .subSide p{
        max-width: unset;
        margin-bottom:20px;
    }

.mobCenter{
    display:block;
    text-align: center;
}
    .inputs{
        flex-direction: column;
    }
    .regPage input{
        width:100%!important;
    }
    .regPage label{
        width:63px;
    }
    .regPage input[type='radio']{
        width:1px !important;
    }
    .addPage{
        flex-direction: column;
    }
    .submitArtwork.dashboard{
        width:100%;
        flex-direction: column;
    }.submitArtwork.dashboard .firstForm{
        width:100%;
    }
    .submitArtworkButton{
        width:100%;
        margin-left:0px;
        height:30px;
    }
    .shopSearching li{
        font-size:16px;
        line-height: 16px;
        margin-top:4px;
    }
    .slider_values span, .searchPrice input{
        font-size:16px!important;
    }
    .MuiSlider-marked{
        top:-3px!important;
    }
    .slider_values span{
        top:10px;
    }
    .shopSearching .searchYears{
        margin-top:0px;
        height:45px;
        padding-left:0px!important;
        padding-right:0px!important;
    }
    .searchBox.clear{
        margin-top:40px;
        width:100%!important;
        padding-right:0px;
    }
    .MuiSlider-marked{
        margin-left:0px!important;
    }
    .searchBox.clear button{
        width:100%!important;
    }
    .shopSearching button.search{
        top:11px;
        width:14px;
        height:14px;
        background-size:14px 14px;
    }
    .shopSearching .searchTitle input{
        font-size:16px;
    }
    .details .contact-form.dashboard{
        flex-direction: column;
        .form{
            width:100%;
        }
        .updateAddress{
            max-height:224px;
            width:100%;
            padding-top:12px;
            padding-bottom:12px;
        }
    }
    .addPage .addonImages.picBox .picture{
        margin-left:0px;
    }
    .addPage .addonImages.picBox .picture a{
        width:31%;
    }

    
    .artistFullPage .bio h1{
        font-size:32px;
        line-height:32px;
    }
    .secondLine{
        padding-top:8px;
    }
    footer .secondLine .social-list{
        top:4px;
    }
    .downloadMobile{
        display:block;
    }
    .dispHide{
        display:none;
    }
    .progress{
        display:none!important;
    }
    .mobilePics{
        padding-left:10px;
        display:block;
        .pic{
            display:block;
            margin-top:24px;
        }
        img{
            max-width:188px;
        }
    }
    .nonMobile{
        display:none;
    }
    .slide .cert a .text{
        max-width:161px;
    }
    .square a:before{
        padding-top:5%;
    }
    .square a:after{
        padding-top:5%;
    }
    .special_projects h3{
        margin-bottom:0px;
    }
    .special_projects{
     
        .slick-slide{
            padding-top:10px;
            padding-right:8px;
        }
    }
    .trendMenu.allMenu{
        width:calc(100% - 8px);
        display:flex;
        flex-direction: row;
          flex-wrap: wrap;

        justify-content: space-between;
        margin-top:48px;
        
        div{
            line-height:23px;
            width:auto!important;
            padding:auto!important;
            flex:25%;
            //flex: 1 0 20%;
            padding-right:0px!important;
        }      
        div:nth-child(2),div:nth-child(3){
           //flex: 1 0 23%;
        }   
       div:nth-child(4){
            //flex: 17px;
        }
        div:nth-child(1){
            //flex: 50px;
        }
    }
}

.red.error{
    position:relative;
    top:-8px;
}