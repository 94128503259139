.banners-wrapper {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner-item-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 400px;
    padding: 40px;
    img {
      display: none;
    }
    p {
      color: white;
      font-size: 25px;
    }
    .btn-placebid {
      height: 65px;
      width: 135px;
      font-size: 17px;
      color: black;
      background-color: white;
      margin-top: 30px;
    }
    .banner-item-content-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .orange {
    background-color: #ffc481;
  }
  .blue {
    background-color: #81c4ff;
  }
}

@media screen and (min-width: 1080px) {
  .banners-wrapper {
    flex-direction: row;
    .banner-item-wrapper {
      height: 350px;
      img {
        //   min-width: 123px;
        display: unset;
        max-width: 200px;
        max-height: 350px;
      }
    }
  }
}
