.footerBg{
  padding-left:10px;
  padding-right:10px;
}

footer {
  margin-top:64px;
  width: 100%;
  border-top:1px solid #000;
  padding-top:14px;
  h2{
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
    margin-bottom:6px;
  }
  p,a{
    margin-bottom:12px;
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  a{
    text-decoration:none;
  }
  a.download{
    text-decoration:none;
    border-top:1px solid black;
    border-bottom:1px solid black;
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color:#000;
    padding:4px 16px;
    width:100%;
    text-align: center;
  }
  .maxWidth{
    max-width:500px;
  }
  h4{
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom:16px;
    color: #000000;
  }
  .secondLine{
    margin-top:96px;
    border-top:1px solid black;
 
    padding-bottom:10px;
    overflow: hidden;
    img{
      float:left;
    }
    ul{
      padding:0px;
      margin:0px;
      float:left;
      position: relative;
      top:-3px;
      li{
        float:left;
        a{
          text-decoration:none;
          font-family: 'MonumentGrotesk-Medium';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #000000;
          padding:0px 12px;
        }
      }
    }
  }
  .social-list{
    position: relative;
    top:-5px;
    a{
      margin-right:16px;
    }
  }
}