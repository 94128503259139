.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;

    font-weight: 500;
font-size: 22px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1420px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 5px 0;
  }
  
  .cards__items {
    min-width: 380px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    // box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    // border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height:100%;
    max-height: 100%;
    transition: all 0.2s linear;
  }

  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {

    text-align: center;
    font-weight: 400;
font-size: 18px;
line-height: 20px;
color: #000000;
  }

  .hidden_text {
    color:#ffffff;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }

  @media only screen and (min-width: 1500px) {
    .cards__item__pic-wrap {
      padding-top: 75%;
    }
  }
  @media only screen and (min-width: 1800px) {
    .cards__item__pic-wrap {
      padding-top: 85%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
    .cards__item__pic-wrap {
      padding-top: 90%;
    }
  }
  
  
  @media only screen and (max-width: 500px) {
    .cards__item{
      margin-right:0px;
    }
    .cards__item__info{
      padding-left:0px;
      padding-right:0px;
    }
  }