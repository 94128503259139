.hero-wrapper {
  overflow: hidden;
  z-index: 5;
}

.slide { 
  .cap{
    width:33.33%;
    float:left;
    padding-left:10px;
    h1{
      font-family: 'Loos Normal';
      letter-spacing: -1px;
      font-style: normal;
      max-width:458px;
      font-weight: 400;
      font-size: 72px;
      line-height: 72px;
      min-height:50vh;
      color: #000000;
      text-align: left;
    }
    .text{
      display:inline-block;
      font-family: 'MonumentGrotesk-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      font-feature-settings: 'salt' on;
      color: #000000;

    }
  }
  .img{
    position:relative;
    overflow:hidden;
    width:66.67%;
    img{
      width:100%;
      float:right;
      height:calc(100vh - 150px);
      object-fit: cover;
    }
    .author{
      width:118px;
      position:absolute;
      bottom:0;
      right:0;
      background: rgba(0, 0, 0, 0.3);
      padding:4px 10px;      
      p{
        margin-bottom:0px;
        font-family: 'MonumentGrotesk-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      font-feature-settings: 'salt' on;
      }
    }
 
  }
  .cert a{
    display:inline-block;
    width:calc(50% - 5px);
    height:calc(50vh - 16px);
    position:relative;
    .text{
      width: 200px;
    }
  }
  a{
    font-family: 'MonumentGrotesk-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    border-top:1px solid #000;
    text-decoration: none;
    span.icon{
      display:block;
      width:14px;
      height:14px;
      margin-top:10px;
      margin-bottom:5px;
    }
    span.learnMore{
      font-family: 'MonumentGrotesk-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      position:absolute;
      bottom:0;
      left:0;
      display:block;
      border-bottom:1px solid #000;
      width:100%;
      padding-bottom:4px;
    }
  }
  .certificate{
    float:left;
    transition:0.5s all;
    span.icon{
      transition:0.5s all;
      background:url('../assets/icons/certificate.svg') no-repeat;
    }
    span.learnMore{
      transition:0.5s all;
    }
    &:hover{
      padding-left:8px;
      background:black;
      color:white;
      span.learnMore{
        padding-left:8px;
      }
      span.icon{
        background:url('../assets/icons/certificateHover.svg') no-repeat;
      }
      span{
        color:white;
      }
    }
  }
  .delivery{
    float:right;
    transition:0.5s all;
    span.text{
      max-width:125px;
      transition:0.5s all;
    }
    span.learnMore{
      transition:0.5s all;
    }
    span.icon{
      transition:0.5s all;
      width:18px;
      background:url('../assets/icons/delivery.svg') no-repeat;
    }
    &:hover{
      padding-left:8px;
      background:black;
      color:white;
      span.learnMore{
        padding-left:8px;
      }
      span.icon{
        background:url('../assets/icons/deliveryHover.svg') no-repeat;
      }
      span{
        color:white;
      }
    }
  }
}
.slide{
  display:flex;
  gap:10px;
}
@media only screen and (max-width: 991px) {
  .slide{
    flex-direction: column-reverse;
    .cap,.img{
      width:100%;
    }
    .img img{
      height:auto;
    }
    .cap{
      h1{
        font-size:32px;
        line-height:32px;
        min-height:auto;
        margin-top:16px;
        margin-bottom:16px;
      }
    }
    .img .author{
      display:none;
    }
  }
}